import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import Notification from "./shared/notifications/notifications";
import LandingPage from './pages/landingPage/LandingPage';
import {URLS} from "./helpers/urls"
import "./App.scss"
import Linking from "./pages/PaymentLinking/Linking";

const App: React.FC<{url:string}> = ({ url }) => {
  
  return (
    <div className={`container-fluid`}>
      <div style={{zIndex: "1000000000 !important"}}><Notification /></div>
       <Switch>
        <Route path={URLS.HOME} component={LandingPage} />
      </Switch>
    </div>
  );
};

export default App;
