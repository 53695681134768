import React, { useEffect } from "react";
import "./dashboard.scss";
import CustomTab from "../../components/customTab/CustomTab";
import URLS from "../../helpers/urls";
import Overview from "../Overview/Overview";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";
import ChargeBack from "../chargeBack/ChargeBack";
import Links from "../Links/Links";
import Transactions from "../transactions/Transactions";
import Settlements from "../settlements/Settlements";
import Settings from "../settings/Settings";

const tabItems = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
  },
  {
    id: 3,
    title: "Transactions",
    key: "transactions",
    routeLink: URLS.Transactions,
  },
  {
    id: 4,
    title: "Settlement",
    key: "settlements",
    routeLink: URLS.Settlements,
    subMenu: [
      {value:"Overview", link:URLS.SettlementOverview},
      {value: "Sub Account", link: URLS.SettlementsSubAccount}, 
      {value: "Settlement Profile", link: URLS.SettlementsProfile}],
  },
  {
    id: 5,
    title: "Chargeback",
    key: "chargback",
    routeLink: URLS.ChargeBack,
  },
  {
    id: 7,
    title: "Links",
    key: "links",
    routeLink: URLS.Linking,
  },
  {
    id: 6,
    title: "Settings",
    key: "settings",
    routeLink: URLS.Setting,
  }
];

const Dashboard = () => {
  const history = useHistory();

  useEffect(() => {
    if(history.location.pathname ==="/collect-service") {
      history.push(URLS.Overview);
    }else{
      const path = history.location.pathname.split('/');
      const index = path.indexOf("app");
      const urlPath = path.splice(index, path.length);
      history.push(`/${urlPath.join('/')}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details">
      <BackButton label="Collect" />

      <section className="content-section" data-testid="content-section">
        <CustomTab data={tabItems}>
          <Switch>
             <Route exact path={URLS.Overview} component={Overview} />
              <Route exact path={URLS.Transactions} component={Transactions} />
              <Route path={URLS.Settlements} component={Settlements} />
              <Route exact path={URLS.ChargeBack} component={ChargeBack} />
              <Route exact path={URLS.Linking} component={Links} />
              <Route exact path={URLS.Setting} component={Settings} />
            
            </Switch>
        </CustomTab>
      </section>
    </div>
  );
};

export default Dashboard;
