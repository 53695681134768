import React from "react";
import {Spinner, Button} from "reactstrap";

type PropsType = {
    label: string, 
    loading?: boolean, 
    className?:string, 
    background?:string, 
    size?:string, 
    width?:string,
    spinnerColor?:string,
    onClick:any

}
const ButtonWrapper: React.FC<PropsType> = ({label, loading, onClick, className, background="blue",spinnerColor="white", size="md", width="100%"}) => {
  console.log({label})
    return <Button
    className={className}
    color={background}
    size={size}
    disabled={loading}
    width={width}
    onClick={onClick}
  >{label} {loading && (
    <Spinner
      size="sm"
      color={spinnerColor}
    />)}
    </Button>
}

export default ButtonWrapper