import React, { useState } from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import "./toggleModal.scss";

const ToggleModal = ({ title, toggleForm,showToggleModal, onToggle, toggling, toggleBtn, textContent}) => {
  return (
    <div >
      <Modal
        centered={true}
        fade={false}
        toggle={onToggle}
        isOpen={showToggleModal}
        backdrop="static"
        size="sm"
        className="toggle-modal-wrapper"
      >
        <ModalHeader toggle={toggleForm}>
          <div className="title">{title}</div>
        </ModalHeader>
        <ModalBody>
          <div className="content">
           <div className="desc">{textContent}</div>
           <div className="btns">
              <Button onClick={toggleForm} className="cancel" label="Cancel"/>
              <Button
            onClick={onToggle}
            label={
              toggling ? (
                <Spinner className="me-1" size="sm" color="light"></Spinner>
              ) : (
                <>{toggleBtn}</>
              )
            }
            background="blue"
            className="w-100"
            disabled={toggling}
          />
           </div>
           </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ToggleModal;
