import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux"

import "./settings.scss";
import Select from 'react-select'
import Button from "../../components/button/Button";

import { Col, Row } from "reactstrap";
import { getBanks, getCountry, getCurrencies, getNameEnquiry, getOnboardingStatus, getSettingAccount, getSupportedCountry, postPreference, postSettingAccount } from "../../redux/actions/action";
import { errorMessage, successMessage } from "../../redux/actions/notification";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import SwitchPrefereceModal from "./SwitchPreference";
import { debounce } from "lodash";

type PayloadType = {
    country?: any,
    bank?: any,
    countryCode?: string,
    accountNumber?: string,
    bankCode?: string,
    accountName?: string
    currency?: any
    currencyCode?: string
}
const Settings = () => {
    const [payload, setPayload] = useState<PayloadType>();
    const [submitStatus, setSubmitStatus] = useState(false);
  const [openPreferenceSwitch, setOpenPreferenceSwitch] = useState(false);
  const [preference, setPreference] = useState("");
  const [preferencevalue, setPreferenceValue] = useState("");
  const [preferenceLabel, setPreferenceLabel] = useState("");
  const preferences = [{type:"CorporateWallet", value:"Set to Corporate Wallet"}, {type:"Collect", value:"Set to Collection Account"}, {type:"SettlementBank", value:"Set to Bank"}]
    const dispatch = useDispatch();
    
    const {getOnboardingStatusSuccess, supportedCountryPayload, currenciesPayload,getBanksLoading, postPreferenceError, postSettingAccountError, getSettingAccountLoading, getSettingAccountSuccess, getBanksSuccess, postSettingAccountLoading, postSettingAccountSuccess, postPreferenceLoading, postPreferenceSuccess, bankNameEnquiryLoading, bankNameEnquirySuccess} = useSelector(({ reducer }:any) => {
      return {
        getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
        currenciesPayload: reducer?.currenciesPayload,
        currenciesLoading: reducer?.currenciesLoading,
        getBanksSuccess: reducer?.getBanksSuccess,
        getBanksLoading: reducer?.getBanksLoading,
        getCountrySuccess: reducer?.getCountrySuccess,
        getCountryLoading: reducer?.getCountryLoading,
        getCountryError: reducer?.getCountryError,
        bankNameEnquirySuccess: reducer?.bankNameEnquirySuccess,
      bankNameEnquiryLoading: reducer?.bankNameEnquiryLoading,
      bankNameEnquiryError: reducer?.bankNameEnquiryError,
        getSettingAccountSuccess: reducer?.getSettingAccountSuccess,
        getSettingAccountLoading: reducer?.getSettingAccountLoading,
        postSettingAccountSuccess: reducer?.postSettingAccountSuccess,
        postSettingAccountLoading: reducer?.postSettingAccountLoading,
        postSettingAccountError: reducer?.postSettingAccountError,
        postPreferenceSuccess: reducer?.postPreferenceSuccess,
        postPreferenceLoading: reducer?.postPreferenceLoading,
        postPreferenceError: reducer?.postPreferenceError,
        supportedCountryPayload: reducer?.supportedCountryPayload,
      supportedCountryLoading: reducer?.supportedCountryLoading,
      supportedCountryError: reducer?.supportedCountryError,
    
      };
    });
   
  const onSelectCountry = (e: any) => {
    dispatch(getBanks(e.value));
    setPayload({...payload, countryCode: e?.value, country: e})
  }

  const submit = () => {
    setSubmitStatus(true);
    if(payload?.accountName && payload.countryCode && payload.bankCode && payload.currencyCode) {
      dispatch(postSettingAccount(payload, true))
    }
  }

  const debounceText = useCallback(debounce(async(srhTxt) => {
    if(payload?.bankCode && payload?.countryCode) {
      dispatch(getNameEnquiry(srhTxt, payload.bankCode, payload.countryCode ));
    }
  }, 2000), [payload?.bankCode, payload?.countryCode])
  
  
  const handleSearchChange = (e: any) => {
    debounceText(e.target.value)
    setPayload({...payload, accountNumber: e.target.value})
  };
  
  const onSetPreference = (type: string, label: string) => {
    setPreference(type)
    setPreferenceLabel(label)
    setOpenPreferenceSwitch(true)
  }
  const savePreference = () => {
    dispatch(postPreference({"settlementPreference": preference}))
  };
  

  useEffect(()=>{
    dispatch(getCountry());
    dispatch(getCurrencies());
    dispatch(getSettingAccount());
    dispatch(getSupportedCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(payload?.bankCode && payload?.countryCode && payload?.accountNumber) {
      dispatch(getNameEnquiry(payload.accountNumber, payload?.bankCode, payload.countryCode ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.bankCode, payload?.countryCode])


  useEffect(()=> {
   if(postSettingAccountSuccess?.data?.requestSuccessful && !postSettingAccountLoading) {
     dispatch(successMessage("Sub-Account creation was successful"))
     dispatch(getSettingAccount());
   }else if(postSettingAccountSuccess?.data?.requestSuccessful ===false && !postSettingAccountLoading) {
    dispatch(errorMessage(postSettingAccountSuccess?.data?.message|| "Unable to create Sub-Account, please try again"))
   }else if(postSettingAccountError && !postSettingAccountLoading) {
    dispatch(errorMessage(postSettingAccountError?.data?.message|| "Unable to create Sub-Account, please try again"))
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSettingAccountLoading])
  
  useEffect(()=> {
   if(postPreferenceSuccess?.data?.requestSuccessful && !postPreferenceLoading) {
     dispatch(successMessage(`${preferenceLabel} preference was successful`))
     setOpenPreferenceSwitch(false);
     setPreferenceValue(preference)
   }else if(postPreferenceError && !postPreferenceLoading) {
    dispatch(errorMessage(postPreferenceError?.data?.message|| "Unable to create Sub-Account, please try again"))
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postPreferenceLoading])

  useEffect(()=> {
   if(getOnboardingStatusSuccess) {
    setPreferenceValue(getOnboardingStatusSuccess?.data?.responseData?.settlementPreference)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnboardingStatusSuccess])

  useEffect(()=> {
   if(!bankNameEnquiryLoading && bankNameEnquirySuccess?.data?.responseData) {
     setPayload({...payload, accountName: bankNameEnquirySuccess?.data?.responseData})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankNameEnquiryLoading])

  useEffect(()=> {
   if(getSettingAccountSuccess?.data?.requestSuccessful && !getSettingAccountLoading) {
       const data = getSettingAccountSuccess?.data?.responseData?.data?.[0]||{}
       setPayload({...payload, ...data, bank: {value:  data?.bankCode, label: data?.bankName||""}, currency: {value: data?.currencyCode , label: data?.currencyCode}})
   }else if(getSettingAccountSuccess?.data?.requestSuccessful === false && !getSettingAccountLoading) {
    dispatch(errorMessage(getSettingAccountSuccess?.data?.message|| "Unable to retrieve Sub-Account, please try again"))
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSettingAccountLoading])

  return (
    <div className="settings-container">
        <div className="title">Country</div>
        <div className="form-group">
            <label className="">Active Country</label>
            <div className="select-country">
            <Select
            options={supportedCountryPayload?.data?.map(val =>({value: val?.countryCode, label: val?.countryName}))}
                //  options={getCountrySuccess?.data?.responseData?.items?.map(val =>({value: val?.isO3, label: val?.name}))}
                  value={payload?.country} onChange={onSelectCountry}/>
            </div>
        </div>
        {submitStatus && !payload?.countryCode && <div className="error-message">Country is required</div>}

       {getBanksLoading && !getBanksSuccess ?<div className="content-loading">
                <CustomSpinner height="75vh" label="Loading banks" />
              </div>:<><div className="settlement">
            <div className="title">Main Settlement Account</div>
            <Row>
                <Col md="6">
                  <Row>
                    <Col md="6">
                        <div className="form-group">
                <label htmlFor="sub-account">Account Name</label>
                <input className="form-control" disabled={true} value={bankNameEnquirySuccess?.data?.responseData} type="text"/>
                        </div>
                        {bankNameEnquiryLoading && <div className="error-message">Fetching account name</div>}
              {submitStatus && !payload?.accountName && <div className="error-message">Account Name is required</div>} </Col>
                    <Col md="6">
                        <div className="form-group">
                            <label>Account Number</label>
                            <input className="form-control" value={payload?.accountNumber} type="number"  onChange={handleSearchChange}/>
                        </div>
                        {submitStatus && !payload?.accountNumber && <div className="error-message">Account Number is required</div>}
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col md="6">
                        <div className="form-group">
                            <label>Bank Name</label>
                            <Select options={getBanksSuccess?.data?.responseData?.map(val =>({value: val?.bankCode, label: val?.bankName}))} value={payload?.bank} onChange={(e) =>setPayload({...payload, bankCode: e?.value, bank: e})}/>
                        </div>
                        {submitStatus && !payload?.bankCode && <div className="error-message">Bank is required</div>}
                    </Col>
                    <Col md="6">
                    <div className="form-group">
                        <label className="">Currency</label>
                        <div className="select-country">
                        <Select
                            options={currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))}
                            value={payload?.currency} onChange={(e)=>setPayload({...payload, currencyCode: e.value, currency: e})}/>
                        </div>
                    </div>
                    {submitStatus && !payload?.currencyCode && <div className="error-message">Currency is required</div>}
                </Col>
                    </Row>
                    <Button
                label="Update"
                background="primary" 
                size="md"
                width="118px"
                onClick={submit}
                loading={postSettingAccountLoading}
              />
                </Col>
            </Row>
        </div>
        <div>
            <div className="title">Preference</div>
            <Row>
                <Col md="6">
                  <Row>
                    <Col>
                        <div className="preferences">
                            <label>Settlement Preference:</label>
                            <div className="preference-select">
                              {preferences.map((val, i)=> (<div className="preference"><input type="radio" className="radio-input" id={val.type} value={val.type} checked={preferencevalue === val.type} onChange={()=>onSetPreference(val.type, val.value)}/> <label htmlFor={val.type}>{val.value}</label></div>))}
                            </div>
                        </div>
                    </Col>
                    </Row>
                   
                </Col>
            </Row>
        </div> 
   
    
        </>
         }
   
        <SwitchPrefereceModal label={preferenceLabel} savePreference={savePreference} postPreferenceLoading={postPreferenceLoading} openPreferenceSwitch={openPreferenceSwitch} setOpenPreferenceSwitch={setOpenPreferenceSwitch}/>
    </div>
  );
};

export default Settings;
