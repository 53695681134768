import React from "react";
import Button from "../../components/button/Button";

import CustomModal from "../../components/modal/Modal";
import { CancelIcon } from "../../components/icons";
import {CautionIcon} from "../../assets/icons/index";


const SwitchPrefereceModal = ({savePreference, postPreferenceLoading, openPreferenceSwitch, setOpenPreferenceSwitch, label}) => (
    <CustomModal
    openModal={openPreferenceSwitch}
    setOpenModal={() => setOpenPreferenceSwitch}
  >
    <div className="preference-switch-container"> 
      <div className="modal-title">Switch Preference <span className="cursor" onClick={()=>setOpenPreferenceSwitch(false)}><CancelIcon /></span></div>
       <div className="switch-content">
         <div className="switch-img">
           <CautionIcon/>
         </div>
           <div className="switch">Switch to “{label}”?</div>
           <div className="switch-desc">You’re about to switch your preference. Are you sure about this decision?</div>

       </div>
       <div>
          <Button
        label="Switch"
        className="open-btn"
        background="primary"
        size="md"
        width="100%"
        onClick={savePreference}
        loading={postPreferenceLoading}
      />
      </div>
      <div>
          <Button
        label="Cancel"
        className="switch-cancel"
        size="md"
        width="100%"
        onClick={()=>setOpenPreferenceSwitch(false)}
      />
      </div>
      </div>
  </CustomModal>

)

export default SwitchPrefereceModal