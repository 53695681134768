import React from "react";

const TransactionIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12703_18894)">
<path d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM4 5V19H20V5H4ZM9 11H11V13H9V15H7V13H5V11H7V9H9V11ZM13 11H19V13H13V11Z" fill="#EA6262"/>
</g>
<defs>
<clipPath id="clip0_12703_18894">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


export default TransactionIcon