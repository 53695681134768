import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import BusinessLinkForm from "./BusinessLinkForm";
import "./linkModal.scss";

const LinkModal = ({ show, payload, toggleModal }) => {
  return (
    <div >
      <Modal
        centered={true}
        fade={false}
        toggle={toggleModal}
        isOpen={show}
        backdrop="static"
        className="link-modal-wrapper"
      >
        <ModalHeader toggle={toggleModal}>
          <div className="title">Create new link</div>
        </ModalHeader>
        <ModalBody>
           <BusinessLinkForm data={payload} toggleModal={toggleModal}/>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LinkModal;
