import React, {useEffect, useState} from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart:React.FC<{data?: any}> = ({data}) => {
  const [labels, setLabels] = useState<any>([]);
  const [list, setList] = useState<any>([]);

    useEffect(()=> {
      let labelItems:any = []; 
      let listItems: any = []; 
        for(let dt in data) {
            labelItems.push(dt)
            listItems.push(Number(data[dt]))
        }
        setLabels(labelItems)
        setList(listItems)
    }, [data]);

    
    const options = {
        labels,
        datasets: [
          {
            data: list,
            backgroundColor: ["#FFCE56", "#36A2EB", "#41d87d", "#FF6384"],
            hoverBackgroundColor: ["#FFCE56", "#36A2EB", "#41d87d", "#FF6384"], 
            borderWidth: 2
          }
        ]
      };
   return (
     <Doughnut data={options} />
   )
}

export default DoughnutChart