import BackButton from "../../components/backButton/BackButton";
import { useState, useEffect } from "react";
import {useSelector} from "react-redux";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./onboardingPage.scss";
import OnBoardingModal from "./onboardModal/OnboardModal";

const OnboardingPage = () => {
  const [open, setOpen] = useState(false);
  const [notApprovedCountry, setNotApprovedCountry] = useState(null);
  
  const { getOnboardingStatusSuccess, getOnboardingStatusLoading } = useSelector(({ reducer }:any) => {
    return {
      onBoardingSuccess: reducer?.onBoardingSuccess,
      onBoardingLoading: reducer?.onBoardingLoading,
      onBoardingError: reducer?.onBoardingError,
      getCountrySuccess: reducer?.getCountrySuccess,
      getCountryLoading: reducer?.getCountryLoading,
      getCountryError: reducer?.getCountryError,
      getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
      getOnboardingStatusLoading: reducer?.getOnboardingStatusLoading,
    };
  });
  
  useEffect(()=>{
    if(getOnboardingStatusSuccess?.data?.requestSuccessful && getOnboardingStatusSuccess?.data?.responseData) {
      for(let config of getOnboardingStatusSuccess?.data?.responseData?.merchantConfigs) {
        if(config?.isApproved) {
          setNotApprovedCountry(null);
          return
        }
        setNotApprovedCountry(config)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnboardingStatusLoading]);
  
  return (
    <>
    {!notApprovedCountry && <div className="onboarding-container">
      <BackButton label="Collect" />
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography typographyVariant="section-title" text="Collect money in minutes." />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
          BaseOne empowers you to create unlimited virtual accounts for your users and customers. Leverage the power 
of BaseOne to start creating virtual account numbers on a provider bank of your choice. Get started by 
choosing a provider bank.
          </p>
        </div>
        <div>
          <Button
            label="Onboard"
            background="blue"
            size="md"
            className="onboard-btn"
            onClick={() => setOpen(true)}
          />
          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() => window.open("https://developer.baseone.co", "_blank")}
            background="neutral"
            size="md"
            className="learn-more"
          />
        </div>
        <p className="footnote">Note: BaseOne empowers you to create unlimited virtual accounts for your users and customers.</p>
      </section>
    </div>}
    <OnBoardingModal open={open} setOpen={setOpen} notApprovedCountry={notApprovedCountry}/>   

    </>
  );
}

export default OnboardingPage;