import moment from "moment";

export const TransactionHistoryChartOptions = (currency:string) => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: '',
      },
    }
});

export const DateFormat = (date:any) => {
    return date.split(".")[0]
    // if(date){
    //    return moment(new Date(date.split(".")[0]).toLocaleString()).format('YYYY-MM-DD HH:mm:ss')
    // }else {
    //     return null
    // }
}
  