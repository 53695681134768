import { Type } from "../actions/action";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const Reducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(Type.GET_BANKS):
      return {
        ...state,
        getBanksSuccess: null,
        getBanksError: null,
        getBanksLoading: true,
      };
    case SUCCESS(Type.GET_BANKS):
      return {
        ...state,
        getBanksLoading: false,
        getBanksSuccess: actions.payload,
        getBanksError: null,
      };
    case FAILURE(Type.GET_BANKS):
      return {
        ...state,
        getBanksLoading: false,
        getBanksSuccess: null,
        getBanksError: actions.payload,
      };
    case REQUEST(Type.GET_TRANSACTION):
      return {
        ...state,
        getTransactionSuccess: null,
        getTransactionError: null,
        getTransactionLoading: true,
      };
    case SUCCESS(Type.GET_TRANSACTION):
      return {
        ...state,
        getTransactionLoading: false,
        getTransactionSuccess: actions.payload,
        getTransactionError: null,
      };
    case FAILURE(Type.GET_TRANSACTION):
      return {
        ...state,
        getTransactionLoading: false,
        getTransactionSuccess: null,
        getTransactionError: actions.payload,
      };
    case REQUEST(Type.GET_SETTLEMENT_OVERVIEW):
      return {
        ...state,
        getSettlementOverviewSuccess: null,
        getSettlementOverviewError: null,
        getSettlementOverviewLoading: true,
      };
    case SUCCESS(Type.GET_SETTLEMENT_OVERVIEW):
      return {
        ...state,
        getSettlementOverviewLoading: false,
        getSettlementOverviewSuccess: actions.payload,
        getSettlementOverviewError: null,
      };
    case FAILURE(Type.GET_SETTLEMENT_OVERVIEW):
      return {
        ...state,
        getSettlementOverviewLoading: false,
        getSettlementOverviewSuccess: null,
        getSettlementOverviewError: actions.payload,
      };
    case REQUEST(Type.ONBOARDING_STATUS):
      return {
        ...state,
        getOnboardingStatusSuccess: null,
        getOnboardingStatusError: null,
        getOnboardingStatusLoading: true,
      };
    case SUCCESS(Type.ONBOARDING_STATUS):
      return {
        ...state,
        getOnboardingStatusLoading: false,
        getOnboardingStatusSuccess: actions.payload,
        getOnboardingStatusError: null,
      };
    case FAILURE(Type.ONBOARDING_STATUS):
      return {
        ...state,
        getOnboardingStatusLoading: false,
        getOnboardingStatusSuccess: null,
        getOnboardingStatusError: actions.payload,
      };
    case REQUEST(Type.POST_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        postSettlementSubAccountSuccess: null,
        postSettlementSubAccountError: null,
        postSettlementSubAccountLoading: true,
      };
    case SUCCESS(Type.POST_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        postSettlementSubAccountLoading: false,
        postSettlementSubAccountSuccess: actions.payload,
        postSettlementSubAccountError: null,
      };
    case FAILURE(Type.POST_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        postSettlementSubAccountLoading: false,
        postSettlementSubAccountSuccess: null,
        postSettlementSubAccountError: actions.payload,
      };
    case REQUEST(Type.GET_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        getSettlementSubAccountSuccess: null,
        getSettlementSubAccountError: null,
        getSettlementSubAccountLoading: true,
      };
    case SUCCESS(Type.GET_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        getSettlementSubAccountLoading: false,
        getSettlementSubAccountSuccess: actions.payload,
        getSettlementSubAccountError: null,
      };
    case FAILURE(Type.GET_SETTLEMENT_SUBACCOUNT):
      return {
        ...state,
        getSettlementSubAccountLoading: false,
        getSettlementSubAccountSuccess: null,
        getSettlementSubAccountError: actions.payload,
      };
    case REQUEST(Type.GET_SETTLEMENT_PROFILE):
      return {
        ...state,
        getSettlementProfileSuccess: null,
        getSettlementProfileError: null,
        getSettlementProfileLoading: true,
      };
    case SUCCESS(Type.GET_SETTLEMENT_PROFILE):
      return {
        ...state,
        getSettlementProfileLoading: false,
        getSettlementProfileSuccess: actions.payload,
        getSettlementProfileError: null,
      };
    case FAILURE(Type.GET_SETTLEMENT_PROFILE):
      return {
        ...state,
        getSettlementProfileLoading: false,
        getSettlementProfileSuccess: null,
        getSettlementProfileError: actions.payload,
      };
    case REQUEST(Type.POST_SETTLEMENT_PROFILE):
      return {
        ...state,
        postSettlementProfileSuccess: null,
        postSettlementProfileError: null,
        postSettlementProfileLoading: true,
      };
    case SUCCESS(Type.POST_SETTLEMENT_PROFILE):
      return {
        ...state,
        postSettlementProfileLoading: false,
        postSettlementProfileSuccess: actions.payload,
        postSettlementProfileError: null,
      };
    case FAILURE(Type.POST_SETTLEMENT_PROFILE):
      return {
        ...state,
        postSettlementProfileLoading: false,
        postSettlementProfileSuccess: null,
        postSettlementProfileError: actions.payload,
      };
    case REQUEST(Type.GET_SETTING_ACCOUNT):
      return {
        ...state,
        getSettingAccountSuccess: null,
        getSettingAccountError: null,
        getSettingAccountLoading: true,
      };
    case SUCCESS(Type.GET_SETTING_ACCOUNT):
      return {
        ...state,
        getSettingAccountLoading: false,
        getSettingAccountSuccess: actions.payload,
        getSettingAccountError: null,
      };
    case FAILURE(Type.GET_SETTING_ACCOUNT):
      return {
        ...state,
        getSettingAccountLoading: false,
        getSettingAccountSuccess: null,
        getSettingAccountError: actions.payload,
      };
    case REQUEST(Type.POST_SETTING_ACCOUNT):
      return {
        ...state,
        postSettingAccountSuccess: null,
        postSettingAccountError: null,
        postSettingAccountLoading: true,
      };
    case SUCCESS(Type.POST_SETTING_ACCOUNT):
      return {
        ...state,
        postSettingAccountLoading: false,
        postSettingAccountSuccess: actions.payload,
        postSettingAccountError: null,
      };
    case FAILURE(Type.POST_SETTING_ACCOUNT):
      return {
        ...state,
        postSettingAccountLoading: false,
        postSettingAccountSuccess: null,
        postSettingAccountError: actions.payload,
      };
    case REQUEST(Type.POST_PREFERENCE):
      return {
        ...state,
        postPreferenceSuccess: null,
        postPreferenceError: null,
        postPreferenceLoading: true,
      };
    case SUCCESS(Type.POST_PREFERENCE):
      return {
        ...state,
        postPreferenceLoading: false,
        postPreferenceSuccess: actions.payload,
        postPreferenceError: null,
      };
    case FAILURE(Type.POST_PREFERENCE):
      return {
        ...state,
        postPreferenceLoading: false,
        postPreferenceSuccess: null,
        postPreferenceError: actions.payload,
      };
    case REQUEST(Type.ONBOARDING):
      return {
        ...state,
        onBoardingSuccess: null,
        onBoardingError: null,
        onBoardingLoading: true,
      };
    case SUCCESS(Type.ONBOARDING):
      return {
        ...state,
        onBoardingLoading: false,
        onBoardingSuccess: actions.payload,
        onBoardingError: null,
      };
    case FAILURE(Type.ONBOARDING):
      return {
        ...state,
        onBoardingLoading: false,
        onBoardingSuccess: null,
        onBoardingError: actions.payload,
      };
    case REQUEST(Type.GET_COUNTRY):
      return {
        ...state,
        getCountrySuccess: null,
        getCountryError: null,
        getCountryLoading: true,
      };
    case SUCCESS(Type.GET_COUNTRY):
      return {
        ...state,
        getCountryLoading: false,
        getCountrySuccess: actions.payload,
        getCountryError: null,
      };
    case FAILURE(Type.GET_COUNTRY):
      return {
        ...state,
        getCountryLoading: false,
        getCountrySuccess: null,
        getCountryError: actions.payload,
      };

    case REQUEST(Type.GET_CURRENCIES):
      return {
        ...state,
        currenciesPayload: null,
        currenciesError: null,
        currenciesLoading: true,
      };
    case SUCCESS(Type.GET_CURRENCIES):
      return {
        ...state,
        currenciesLoading: false,
        currenciesPayload: actions.payload,
        currenciesError: null,
      };
    case FAILURE(Type.GET_CURRENCIES):
      return {
        ...state,
        currenciesLoading: false,
        currenciesPayload: null,
        currenciesError: actions.payload,
      };
    case REQUEST(Type.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        supportedCountryPayload: null,
        supportedCountryError: null,
        supportedCountryLoading: true,
      };
    case SUCCESS(Type.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        supportedCountryLoading: false,
        supportedCountryPayload: actions.payload,
        supportedCountryError: null,
      };
    case FAILURE(Type.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        supportedCountryLoading: false,
        supportedCountryPayload: null,
        supportedCountryError: actions.payload,
      };
    case REQUEST(Type.BANK_NAME_ENQUIRY):
      return {
        ...state,
        bankNameEnquirySuccess: null,
        bankNameEnquiryError: null,
        bankNameEnquiryLoading: true,
      };
    case SUCCESS(Type.BANK_NAME_ENQUIRY):
      return {
        ...state,
        bankNameEnquiryLoading: false,
        bankNameEnquirySuccess: actions.payload,
        bankNameEnquiryError: null,
      };
    case FAILURE(Type.BANK_NAME_ENQUIRY):
      return {
        ...state,
        bankNameEnquiryLoading: false,
        bankNameEnquirySuccess: null,
        bankNameEnquiryError: actions.payload,
      };
    case REQUEST(Type.DELETE_SUB_ACCOUNT):
      return {
        ...state,
        deleteSubAccountSuccess: null,
        deleteSubAccountError: null,
        deleteSubAccountLoading: true,
      };
    case SUCCESS(Type.DELETE_SUB_ACCOUNT):
      return {
        ...state,
        deleteSubAccountLoading: false,
        deleteSubAccountSuccess: actions.payload,
        deleteSubAccountError: null,
      };
    case FAILURE(Type.DELETE_SUB_ACCOUNT):
      return {
        ...state,
        deleteSubAccountLoading: false,
        deleteSubAccountSuccess: null,
        deleteSubAccountError: actions.payload,
      };
    case REQUEST(Type.DELETE_PROFILE):
      return {
        ...state,
        deleteProfileSuccess: null,
        deleteProfileError: null,
        deleteProfileLoading: true,
      };
    case SUCCESS(Type.DELETE_PROFILE):
      return {
        ...state,
        deleteProfileLoading: false,
        deleteProfileSuccess: actions.payload,
        deleteProfileError: null,
      };
    case FAILURE(Type.DELETE_PROFILE):
      return {
        ...state,
        deleteProfileLoading: false,
        deleteProfileSuccess: null,
        deleteProfileError: actions.payload,
      };
    case REQUEST(Type.OVERVIEW_DASHBOARD):
      return {
        ...state,
        collectOverviewSuccess: null,
        collectOverviewError: null,
        collectOverviewLoading: true,
      };
    case SUCCESS(Type.OVERVIEW_DASHBOARD):
      return {
        ...state,
        collectOverviewLoading: false,
        collectOverviewSuccess: actions.payload,
        collectOverviewError: null,
      };
    case FAILURE(Type.OVERVIEW_DASHBOARD):
      return {
        ...state,
        collectOverviewLoading: false,
        collectOverviewSuccess: null,
        collectOverviewError: actions.payload,
      };
    case REQUEST(Type.GET_CHARGEBACK):
      return {
        ...state,
        chargeBackSuccess: null,
        chargeBackError: null,
        chargeBackLoading: true,
      };
    case SUCCESS(Type.GET_CHARGEBACK):
      return {
        ...state,
        chargeBackLoading: false,
        chargeBackSuccess: actions.payload,
        chargeBackError: null,
      };
    case FAILURE(Type.GET_CHARGEBACK):
      return {
        ...state,
        chargeBackLoading: false,
        chargeBackSuccess: null,
        chargeBackError: actions.payload,
      };
    case REQUEST(Type.APPROVE_CHARGEBACK):
      return {
        ...state,
        approveChargeBackSuccess: null,
        approveChargeBackError: null,
        approveChargeBackLoading: true,
      };
    case SUCCESS(Type.APPROVE_CHARGEBACK):
      return {
        ...state,
        approveChargeBackLoading: false,
        approveChargeBackSuccess: actions.payload,
        approveChargeBackError: null,
      };
    case FAILURE(Type.APPROVE_CHARGEBACK):
      return {
        ...state,
        approveChargeBackLoading: false,
        approveChargeBackSuccess: null,
        approveChargeBackError: actions.payload,
      };
    case REQUEST(Type.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadTransactionSuccess: null,
        downloadTransactionError: null,
        downloadTransactionLoading: true,
      };
    case SUCCESS(Type.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadTransactionLoading: false,
        downloadTransactionSuccess: actions.payload,
        downloadTransactionError: null,
      };
    case FAILURE(Type.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadTransactionLoading: false,
        downloadTransactionSuccess: null,
        downloadTransactionError: actions.payload,
      };
    case REQUEST(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationSuccess: null,
        webhookNotificationError: null,
        webhookNotificationLoading: true,
      };
    case SUCCESS(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: actions.payload,
        webhookNotificationError: null,
      };
    case FAILURE(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: null,
        webhookNotificationError: actions.payload,
      };
      case REQUEST(Type.COLLECT_PAYMENT_LINK):
        return {
          ...state,
          collectPaymentLinkSuccess: null,
          collectPaymentLinkError: null,
          collectPaymentLinkLoading: true,
        };
      case SUCCESS(Type.COLLECT_PAYMENT_LINK):
        return {
          ...state,
          collectPaymentLinkLoading: false,
          collectPaymentLinkSuccess: actions.payload,
          collectPaymentLinkError: null,
        };
      case FAILURE(Type.COLLECT_PAYMENT_LINK):
        return {
          ...state,
          collectPaymentLinkLoading: false,
          collectPaymentLinkSuccess: null,
          collectPaymentLinkError: actions.payload,
        };
      case REQUEST(Type.GET_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          getCollectPaymentLinkSuccess: null,
          getCollectPaymentLinkError: null,
          getCollectPaymentLinkLoading: true,
        };
      case SUCCESS(Type.GET_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          getCollectPaymentLinkLoading: false,
          getCollectPaymentLinkSuccess: actions.payload,
          getCollectPaymentLinkError: null,
        };
      case FAILURE(Type.GET_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          getCollectPaymentLinkLoading: false,
          getCollectPaymentLinkSuccess: null,
          getCollectPaymentLinkError: actions.payload,
        };
      case REQUEST(Type.TOGGLE_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          toggleCollectPaymentLinkSuccess: null,
          toggleCollectPaymentLinkError: null,
          toggleCollectPaymentLinkLoading: true,
        };
      case SUCCESS(Type.TOGGLE_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          toggleCollectPaymentLinkLoading: false,
          toggleCollectPaymentLinkSuccess: actions.payload,
          toggleCollectPaymentLinkError: null,
        };
      case FAILURE(Type.TOGGLE_COLLECT_PAYMENT_LINK):
        return {
          ...state,
          toggleCollectPaymentLinkLoading: false,
          toggleCollectPaymentLinkSuccess: null,
          toggleCollectPaymentLinkError: actions.payload,
        };
    default:
      return { ...state };
  }
};

export default Reducer;
