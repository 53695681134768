import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { Spinner } from "reactstrap";
import "./linkModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentLink, postPaymentLink } from "../../../redux/actions/action";
import { errorMessage } from "../../../redux/actions/notification";

type PayloadType = {
  id?: any,
  linkName?: any,
  amount?: any,
  currency?: any,
  description?: string,
  currencyPayload?:any,
  isMultipleUse?:any,
}
const defaultValues = {
  linkName: "",
  amount: 0,
  currency: "",
  description: "",
  currencyPayload: "",
  isMultipleUse: false,
};

const BusinessLinkForm = ({ data,toggleModal }) => {
  const dispatch = useDispatch()
  const [payload, setPayload] = useState<PayloadType>();
  const [submitStatus, setSubmitStatus] = useState(false);

  const { collectPaymentLinkLoading, collectPaymentLinkError, collectPaymentLinkSuccess,currenciesPayload} = useSelector (({ reducer }:any) => {
    return {
      collectPaymentLinkSuccess: reducer?.collectPaymentLinkSuccess,
      collectPaymentLinkLoading: reducer?.collectPaymentLinkLoading,
      collectPaymentLinkError: reducer?.collectPaymentLinkError,
      currenciesPayload: reducer?.currenciesPayload,
      currenciesLoading: reducer?.currenciesLoading,
      currenciesError: reducer?.currenciesError,
     };
  });

  const submitForm = (e) =>{ 
    e.preventDefault();
    let status = true
    setSubmitStatus(true);
    for(let field in payload){
      if(field !== "isMultipleUse" && field !=="id" && !payload[field]){
        status = false
      }
      console.log({[field]: payload[field]})
    }

    if(status && payload){
      dispatch(postPaymentLink(payload, payload?.id))
    }
  }

 
  useEffect(()=>{
    if(data){
      const currencyData = currenciesPayload?.data?.filter(val =>val?.currencyCode === data?.currency)
      setPayload({...data, currency: data?.currency, currencyPayload: { value: currencyData[0]?.currencyCode, label: currencyData[0]?.currencyName }})
    }
  }, [data])
  

  useEffect(()=>{
    if(!collectPaymentLinkLoading && collectPaymentLinkSuccess && submitStatus) {
      setSubmitStatus(false);
      toggleModal();
      dispatch(getPaymentLink(1));
    }else if(!collectPaymentLinkLoading && collectPaymentLinkError && submitStatus) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          collectPaymentLinkError?.data?.message || "Error occurred while downloading"
        )
      );
    }
  }, [collectPaymentLinkLoading])


  return (
    <div className="link-modal-wrapper">
    <form onSubmit={submitForm} className="link-form-content" data-testid="form">
      <div className="d-flex flex-column gap-3 link-modal-wrapper">
        <div className="">
          <label htmlFor="business_name" className="form-label">
             Link Name
          </label>
          <input
              className="form-control"
              type="text"
              value={payload?.linkName}
              id="business_name"
              name="linkName"
              placeholder="Link Name"
              onChange={(e)=>setPayload({...payload, linkName: e.target.value})}
            />
        
          {submitStatus && !payload?.linkName && <div className="invalid-message">
            Link name is required
          </div>}
        </div>
     
        <div className="">
          <label htmlFor="currency" className="form-label">
          Currency
          </label>
          <div className="select-country" data-testid="my-select-component">
                        <Select
                            options={currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))}
                            name="currency"
                            inputId="currency"
                            defaultValue={payload?.currencyPayload}
                            value={payload?.currencyPayload} 
                            onChange={(e)=>setPayload({...payload, currencyPayload: e, currency: e.value})}
                            maxMenuHeight={48}/>
                        </div>
                        {submitStatus && !payload?.currency && <div className="invalid-message">
            Currency is required
          </div>}
         
        </div>

        <div className="">
          <label htmlFor="amount" className="form-label">
          Amount to be paid
          </label>
          <input
              className="form-control"
              type="text"
              value={payload?.amount}
              id="amount"
              onChange={(e)=>setPayload({...payload, amount: e.target.value})}
            />
          {submitStatus && !payload?.amount && <div className="invalid-message">
            Amount is required
          </div>}
        </div>

        <div className="">
          <label htmlFor="description" className="form-label">
          Message
          </label>
          <textarea
            style={{ minHeight: "100px" }}
            className="form-control"
            value={payload?.description}
            id="description"
            onChange={(e)=>setPayload({...payload, description: e.target.value})}
          ></textarea>
          {submitStatus && !payload?.description && <div className="invalid-message">
            Description is required
          </div>}
        </div>

        <div className="">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={payload?.isMultipleUse}
              id="useMultipleTimes"
              onClick={()=>setPayload({...payload, isMultipleUse: !payload?.isMultipleUse})}
            />
            <label className="form-check-label" htmlFor="useMultipleTimes">
              Use multiple times
            </label>
          </div>
        </div>

        <div>
          <Button
            type="submit"
            label={
              collectPaymentLinkLoading ? (
                <Spinner className="me-1" size="sm" color="light"></Spinner>
              ) : (
                <>{payload?.id?"Update":"Create"}</>
              )
            }
            background="blue"
            className="w-100"
            disabled={collectPaymentLinkLoading}
          />
        </div>
      </div>
    </form>
    </div>
  );
};

export default BusinessLinkForm;
