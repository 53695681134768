import React from "react";
import "./backButton.scss";

const BackButton:React.FC<{onClick?: any, label?: string, noPadding?:any}> = ({ onClick, label, noPadding }) => {
  return (
    <section className={`head-section d-flex sp-mb--24 ${noPadding ? "px-0" : ""}`}>
      <div className="head-section__icon d-flex justify-content-center align-items-center" onClick={e => (onClick ? onClick(e) : window.location.replace("/products"))}>
        <i className="ri-arrow-drop-left-line"></i> {label || "Back"}
      </div>
    </section>
  );
};

export default BackButton;
