import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import WebhookIcon from "../../../components/icons/webhook-icon";
import { webhookNotification } from "../../../redux/actions/action";
import { errorMessage, successMessage } from "../../../redux/actions/notification";
import { onError } from "../../../shared/notifications/notifications";
import "./webhookNotification.scss";

const WebhookNotification = ({
  reference,
  show,
  toggleModal
}) => {
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [feedBack, setFeedBack] = useState<{success?: boolean, error?:boolean, message: string}>();
 
  const { webhookNotificationSuccess,webhookNotificationError, webhookNotificationLoading} = useSelector(({ reducer }:any) => {
    return {
      webhookNotificationSuccess: reducer?.webhookNotificationSuccess,
      webhookNotificationLoading: reducer?.webhookNotificationLoading,
      webhookNotificationError: reducer?.getTransactionError,
    };
  });
 
  const onSubmit = () => {
    setSubmitStatus(true);
      dispatch(webhookNotification(reference))
   }

   const closeMsg = () => {
    setFeedBack({error: false, success:false, message:""})
   }

  useEffect(()=>{
    if(!webhookNotificationLoading && webhookNotificationSuccess?.data?.requestSuccessful) {
      toggleModal();
      dispatch(successMessage(webhookNotificationSuccess?.data?.message|| "Webhook notification was successful"))
    }else if(!webhookNotificationLoading && webhookNotificationSuccess?.data?.requestSuccessful === false) {
      setFeedBack({error: true, message: webhookNotificationSuccess?.data?.message||"Error occurred while performing this action"})
    }else if(!webhookNotificationLoading && webhookNotificationError) {
      setFeedBack({error: true, message: webhookNotificationError?.data?.message || "Error occurred while performing this action"})
    }
  }, [webhookNotificationLoading])
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="md"
      className="webhook-notification-modal-container"
    >
              {feedBack?.error && onError(feedBack?.message, closeMsg)}
      <ModalHeader toggle={toggleModal}>
          <span className="renotify">Renotify</span>
      </ModalHeader>
      <ModalBody className="modal__body">
              <div className="content">
                <div className="web-icon"><WebhookIcon/></div>
                <div className="title">Resend webhook notification</div>
                <div className="description">
                Do you want to resend a webhook notification for this transaction? Ensure that this is handled well to avoid duplicate transactions on your system.
                </div>
              </div>
           
             <div>
             <Button
          color="primary"
          onClick={onSubmit}
          className="submit-btn"
          disabled={webhookNotificationLoading}
        >
          {!webhookNotificationLoading?"Renotify":<Spinner size="sm"/>}
        </Button>
             </div>
             <div>
             <Button
          onClick={toggleModal}
          className="cancel-btn"
         >
         Cancel
        </Button>
             </div>
      </ModalBody>
      </Modal>
  );
};

export default WebhookNotification;
