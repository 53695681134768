import "./settlements.scss";
import { Route, Switch } from "react-router-dom";
import Overview from "./overview/Overview";
import SubAccount from "./subAccount/SubAccount";
import Profile from "./profile/Profile";
import URLS from "../../helpers/urls";

const Settlements = () => {
  
  return (
    <div className="settlements-container">
      <Switch>
          <Route exact path={URLS.SettlementOverview} component={Overview} />
          <Route exact path={URLS.SettlementsSubAccount} component={SubAccount} />
          <Route exact path={URLS.SettlementsProfile} component={Profile} />
      </Switch>
    </div>
  );
};

export default Settlements;
