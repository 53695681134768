import { Post, Get, Put, Delete, GetFile } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { collectionUrl, baseUrl, virtualAccountUrl, instanceUrl } from "../../apiServices/config.service";

export const Type = {
  GET_COUNTRY: "GET_COUNTRY",
  GET_CURRENCIES: "GET_CURRENCIES",
  ONBOARDING: "ONBOARDING",
  ONBOARDING_STATUS: "ONBOARDING_STATUS",
  POST_SETTLEMENT_OVERVIEW: "POST_SETTLEMENT_OVERVIEW",
  GET_SETTLEMENT_OVERVIEW: "GET_SETTLEMENT_OVERVIEW",
  GET_SETTLEMENT_PROFILE: "GET_SETTLEMENT_PROFILE",
  POST_SETTLEMENT_PROFILE: "POST_SETTLEMENT_PROFILE",
  GET_SETTLEMENT_SUBACCOUNT: "GET_SETTLEMENT_SUBACCOUNT",
  POST_SETTLEMENT_SUBACCOUNT: "POST_SETTLEMENT_SUBACCOUNT",
  GET_CHARGEBACK: "GET_CHARGEBACK",
  APPROVE_CHARGEBACK: "APPROVE_CHARGEBACK",
  GET_TRANSACTION: "GET_TRANSACTION",
  POST_TRANSACTION: "POST_TRANSACTION",
  GET_SETTING_ACCOUNT: "GET_SETTING_ACCOUNT",
  POST_SETTING_ACCOUNT: "POST_SETTING_ACCOUNT",
  POST_PREFERENCE: "POST_PREFERENCE",
  GET_BANKS: "GET_BANKS",
  GET_SUPPORTED_CURRENCIES: "GET_SUPPORTED_CURRENCIES",
  GET_SUPPORTED_COUNTRIES: "GET_SUPPORTED_COUNTRIES",
  BANK_NAME_ENQUIRY: "BANK_NAME_ENQUIRY",
  OVERVIEW_DASHBOARD: "OVERVIEW_DASHBOARD",
  DELETE_SUB_ACCOUNT: "DELETE_SUB_ACCOUNT",
  DELETE_PROFILE: "DELETE_PROFILE",
  DOWNLOAD_TRANSACTION: "DOWNLOAD_TRANSACTION",
  WEBHOOK_NOTIFICATION: "WEBHOOK_NOTIFICATION",
  COLLECT_PAYMENT_LINK:"COLLECT_PAYMENT_LINK",
  GET_COLLECT_PAYMENT_LINK:"GET_COLLECT_PAYMENT_LINK",
  TOGGLE_COLLECT_PAYMENT_LINK:"TOGGLE_COLLECT_PAYMENT_LINK"
};

export const getBanks = createAsyncAction(
  Type.GET_BANKS,
  async (countryCode) => {
    return await Get(
      `v1/client/institutions/${countryCode}?channel=Bank`, instanceUrl
      );
  }
);

export const getTransactions = createAsyncAction(
  Type.GET_TRANSACTION,
  async (currencySearch="",transactionstatus="", settlementStatus="", pageNumber=1, pageSize=10, transactionReference) => {
    return await Get(
      `v1/transactions?pageSize=${pageSize}&pageNumber=${pageNumber}&currency=${currencySearch}&transactionstatus=${transactionstatus}&settlementStatus=${settlementStatus}&transactionReference=${transactionReference}`, collectionUrl
    );
  }
);

export const getSettlementOverview = createAsyncAction(
  Type.GET_SETTLEMENT_OVERVIEW,
  async (search="",currencySearch="", pageNumber=1, pageSize=10) => {
    return await Get(
      `v1/settlement/transactions?pageSize=${pageSize}&pageNumber=${pageNumber}&AccountNumber=${search}&&Currency=${currencySearch}`, collectionUrl
    );
  }
);

export const postSettlementOverview = createAsyncAction(
  Type.POST_SETTLEMENT_OVERVIEW,
  async (data) => {
    return await Post(
      "v1/settlement/transactions", data, collectionUrl
    );
  }
);
export const getChargeBack = createAsyncAction(
  Type.GET_CHARGEBACK,
  async (pageNumber=1, pageSize=10, currency: string) => {
    if(currency) {
      return await Get(
        `v1/chargebacks/search?pageSize=${pageSize}&pageNumber=${pageNumber}&Currency=${currency}`, collectionUrl
      ); 
    }else{
      return await Get(
        `v1/chargebacks/search?pageSize=${pageSize}&pageNumber=${pageNumber}`, collectionUrl
      );
    }
  }
);

export const approveChargeBack = createAsyncAction(
  Type.APPROVE_CHARGEBACK,
  async (id: number) => {
    return await Put(
      `v1/chargebacks/approve/${id}`, {
        "approvalStatus": "Approved"
      }, collectionUrl
    );
  }
);

export const getSettingAccount = createAsyncAction(
  Type.GET_SETTING_ACCOUNT,
  async (pageNumber=1, pageSize=10) => {
    return await Get(
      `v1/sub-account?pageSize=${pageSize}&pageNumber=${pageNumber}`, collectionUrl
    );
  }
);

export const postSettingAccount = createAsyncAction(
  Type.POST_SETTING_ACCOUNT,
  async (data, IsMainSettlementAccount) => {
    return await Post(
      `v1/sub-account?IsMainSettlementAccount=${IsMainSettlementAccount}`, data, collectionUrl
    );
  }
);
export const postPreference = createAsyncAction(
  Type.POST_PREFERENCE,
  async (data) => {
    return await Put(
      "v1/merchants/settlement-preference", data, collectionUrl
    );
  }
);
export const getSettlementProfile = createAsyncAction(
  Type.GET_SETTLEMENT_PROFILE,
  async (pageNumber=1, pageSize=10) => {
    return await Get(
      `v1/settlement/profile?pageSize=${pageSize}&pageNumber=${pageNumber}`, collectionUrl
    );
  }
);
export const postSettlementProfile = createAsyncAction(
  Type.POST_SETTLEMENT_PROFILE,
  async (data: any, id?: any) => {
    if(id) {
      return await Put(
        `v1/settlement/profile/${id}`, data, collectionUrl
      );
    }else{
      return await Post(
        "v1/settlement/profile", data, collectionUrl
      );
    }
  }
);

export const getSettlementSubAccount = createAsyncAction(
  Type.GET_SETTLEMENT_SUBACCOUNT,
  async (search="", pageNumber=1, pageSize=10) => {
    return await Get(
      `v1/sub-account?pageSize=${pageSize}&pageNumber=${pageNumber}&AccountNumber=${search}`, collectionUrl
    );
  }
);
export const postSettlementSubAccount = createAsyncAction(
  Type.POST_SETTLEMENT_SUBACCOUNT,
  async (data: any, id: any) => {
    if(id) {
    return await Put(
      `v1/sub-account/${id}`, data, collectionUrl
    );
  }else {
    return await Post(
      "v1/sub-account", data, collectionUrl
    );
  }
  }
);
export const deleteSettlementSubAccount = createAsyncAction(
  Type.DELETE_SUB_ACCOUNT,
  async (id: any) => {
     return await Delete(
      `v1/sub-account/${id}`,{}, collectionUrl
    );
  }
);
export const deleteProfile = createAsyncAction(
  Type.DELETE_PROFILE,
  async (id: any) => {
     return await Delete(
      `v1/settlement/profile/${id}`, {}, collectionUrl
    );
  }
);

export const onBoarding = createAsyncAction(
  Type.ONBOARDING,
  async (data) => {
    return await Post(
      "v1/merchants/onboard", data, collectionUrl
    );
  }
);

export const getOnboardingStatus = createAsyncAction(
  Type.ONBOARDING_STATUS,
  async () => {
    return await Get(
      "v1/merchants", collectionUrl
    );
  }
);

export const getCountry = createAsyncAction(
  Type.GET_COUNTRY,
  async () => {
    return await Get(
      "v1/Country/GetAll?pageSize=10000&pageNumber=1&isAscending=true"
    );
  }
);

export const getSupportedCountry = createAsyncAction(
  Type.GET_SUPPORTED_COUNTRIES,
  async (type?:string) => {
    if(type === "setupAccount"){
      return await Get(
        "v1/client/country/getlist", instanceUrl
      );
    }else{
      return await Get(
        "v1/country/supportedCountry", collectionUrl
      );
    }
    
  }
);

export const getCurrencies = createAsyncAction(
  Type.GET_CURRENCIES,
  async (countryCode?:any) => {
    return await Get(
      countryCode?`v1/currency/bycountry/${countryCode}`:"v1/currency", collectionUrl
    );
  }
);

export const getNameEnquiry = createAsyncAction(
  Type.BANK_NAME_ENQUIRY,
  async (accountNumber: string, bankCode: string, countryCode: string) => {
    return await Get(
      `v1/transfer/resolve-bank-account?AccountNumber=${accountNumber}&institutionCode=${bankCode}&countryCode=${countryCode}`, virtualAccountUrl
    );
  }
);
export const getCollectOverview = createAsyncAction(
  Type.OVERVIEW_DASHBOARD,
  async (period: string, currency: string) => {
    if(!period && currency) {
      return await Get(
        `v1/overview?CurrencyCode=${currency}`, collectionUrl
      );
    }else if(period && !currency) {
      return await Get(
        `v1/overview?Period=${period}`, collectionUrl
      );
    }else if(period && currency) {
      return await Get(
        `v1/overview?CurrencyCode=${currency}&Period=${period}`, collectionUrl
      );
    }
  }
);

export const downloadTransaction = createAsyncAction(
  Type.DOWNLOAD_TRANSACTION,
  async (startDate, endDate) => {
    return await GetFile(
      `v1/transactions/download?startDate=${startDate}&endDate=${endDate}`, collectionUrl
    );
  }
);
export const webhookNotification = createAsyncAction(
  Type.WEBHOOK_NOTIFICATION,
  async (reference) => {
    return await Post(
      `v1//transactions/notification`, {"transactionReference": [reference]}, collectionUrl
    );
  }
);

export const postPaymentLink = createAsyncAction(
  Type.COLLECT_PAYMENT_LINK,
  async (data: any, id) => {
    if(id){
      return await Put(
        `v1/paymentlink/config/${id}`, data, collectionUrl
      );
    }
    return await Post(
      `v1/paymentlink/config`, data, collectionUrl
    );
  }
);
export const getPaymentLink = createAsyncAction(
  Type.GET_COLLECT_PAYMENT_LINK,
  async (page, search?:String) => {
    return await Get(
      `v1/paymentlink/config?pageNumber=${page}&&PageNumber=10&&linkName=${search||""}`, collectionUrl
    );
  }
);
export const togglePaymentLink = createAsyncAction(
  Type.TOGGLE_COLLECT_PAYMENT_LINK,
  async (id, status) => {
    return await Put(
      `v1/paymentlink/config/${status?"disable":"enable"}/${id}`,{}, collectionUrl
    );
  }
);