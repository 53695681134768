import axios from "axios";
import { BehaviorSubject } from "rxjs";

import { baseUrl, config } from "./config.service";
import { signout } from "./user.service";


export const Get = async (url, newUrl?) => {
  try {
    return await axios.get(`${newUrl ? newUrl : baseUrl}/${url}`, config());
  } catch (error: any) {
    throw error.response;
  }
};

export const Post = async (url, data, newUrl?) => {
  try {
    return await axios.post(
      `${newUrl ? newUrl : baseUrl}/${url}`,
      data,
      config()
    );
  } catch (error: any) {
    throw error.response;
  }
};

export const Patch = async (url, data) => {
  try {
    return await axios.patch(`${baseUrl}/${url}`, data, config());
  } catch (error: any) {
    throw error.response;
  }
};

export const Put = async (url, data, newUrl) => {
  try {
    return await axios.put(
      `${newUrl ? newUrl : baseUrl}/${url}`,
      data,
      config()
    );
  } catch (error: any) {
    throw error.response;
  }
};

export const Delete = async (url, data, newUrl) => {
  try {
    return await axios.delete(`${newUrl ? newUrl : baseUrl}/${url}`, config());
  } catch (error: any) {
    throw error.response;
  }
};

export const GetFile = async (url, newUrl?) => {
  try {
    return await axios.get(`${newUrl ? newUrl : baseUrl}/${url}`, {...config(),  responseType: "blob"});
  } catch (error: any) {
    throw error.response;
  }
};

