import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

type PropTypes = {
     children?: any,
    openModal: boolean,
    fullscreen?: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
const CustomModal: React.FC<PropTypes> = ({openModal, setOpenModal, children}) => {
  const toggle = () => setOpenModal(!openModal);

  return (
    <div>
      <Modal isOpen={openModal} toggle={toggle} centered={true}>
        <ModalBody>
         {children}
        </ModalBody>
       </Modal>
    </div>
  );
}

export default CustomModal;