import { useEffect, useState } from "react";
import {useSelector, useDispatch} from "react-redux";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "../onboardingPage.scss";
import CustomModal from "../../../components/modal/Modal";
import {CancelIcon, SearchIcon, ArrowRight, SuccessIcon} from "../../../components/icons/index";
import { onBoarding } from "../../../redux/actions/action";

import SuccessMgIcon from "../../../assets/images/success.svg";
import ErrorMgIcon from "../../../assets/images/error.svg";

import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { errorMessage, successMessage } from "../../../redux/actions/notification";
import AwaitingApprovalPage from "../awaitingApprovalPage/AwaitingApprovalPage";
import Icons from "../../../shared/notifications/icons";

const OnboardModal: React.FC<{open: boolean, notApprovedCountry?: any, setOpen: any}> = ({setOpen, notApprovedCountry, open}) => {
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [country, setCountry] = useState<any>([]);
  const [activeCountry, setActiveCountry] = useState<any>([]);
  const [feedBack, setFeedBack] = useState<{success?: boolean, error?:boolean, message: string}>();
  


  const { getOnboardingStatusSuccess, getOnboardingStatusLoading, getOnboardingStatusError, onBoardingSuccess, onBoardingLoading, onBoardingError, supportedCountryPayload, supportedCountryLoading, supportedCountryError } = useSelector(({ reducer }:any) => {
    return {
      getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
      getOnboardingStatusLoading: reducer?.getOnboardingStatusLoading,
      getOnboardingStatusError: reducer?.getOnboardingStatusError,
     onBoardingSuccess: reducer?.onBoardingSuccess,
      onBoardingLoading: reducer?.onBoardingLoading,
      onBoardingError: reducer?.onBoardingError,
      supportedCountryPayload: reducer?.supportedCountryPayload,
      supportedCountryLoading: reducer?.supportedCountryLoading,
      supportedCountryError: reducer?.supportedCountryError,
    };
  });
  
  const onBoard = (countryCode: string) => {
    if(!activeCountry || activeCountry?.indexOf(countryCode) < 0) {
      setFeedBack({error: false, success:false, message:""})
      dispatch(onBoarding({countryCode}))
    }
  }

  const onFilter = (e) => {
    const searchItem = e.target.value;
    if(searchItem !== "") {
      const filtered = supportedCountryPayload?.data?.filter(val => val?.countryName?.toLowerCase()?.indexOf(searchItem?.toLowerCase()) > -1);
      setCountry(filtered)
    }else{
       setCountry(supportedCountryPayload?.data)
    }
  }

  const onSuccess = (message) => {
    return <div className="success">
       <img src={SuccessMgIcon} alt="success icon" />
       {message}
       <span className="cursor" onClick={() => setFeedBack({success: false, message:""})}>{Icons.onboarding("#1E944D")}</span>
     </div>
  }

  const onError = (message) => {
    return <div className="error">
    <img src={ErrorMgIcon} alt="error icon" />
     {message}
    <span className="cursor" onClick={() => setFeedBack({error: false, message:""})}>{Icons.onboarding("#CA1B1B")}</span>
  </div>
  }

  useEffect(()=>{
    if(!onBoardingLoading && onBoardingSuccess?.data?.requestSuccessful) {
       setOpenSuccess(true);
      if(onBoardingSuccess?.data?.responseData?.countries[0]?.countryCode){
        const active_count = activeCountry?activeCountry:[];
        const mapCountries = onBoardingSuccess?.data?.responseData?.countries.map(val => val?.countryCode)
        setActiveCountry([...active_count, ...mapCountries ])
      }
      setFeedBack({success: true, message: "Onboarding was successful"})
    }else if(!onBoardingLoading && onBoardingError) {
      setFeedBack({error: true, message: onBoardingError?.data?.message || "Error occurred while performing this action"})
    }else if(!onBoardingLoading && onBoardingSuccess?.data?.requestSuccessful===false) {
      setFeedBack({error: true, message: onBoardingError?.data?.message || "Error occurred while performing this action"})
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [onBoardingLoading]);

  useEffect(()=>{
    if(!supportedCountryLoading && supportedCountryPayload?.data) {
      setCountry(supportedCountryPayload?.data)
    }else if(!supportedCountryLoading && supportedCountryError) {
      // dispatch(errorMessage("Error occurred while performing this action"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [supportedCountryLoading])

useEffect(()=>{
  if(!getOnboardingStatusLoading && getOnboardingStatusSuccess?.data?.requestSuccessful) {
    const countries = getOnboardingStatusSuccess?.data?.responseData?.merchantConfigs?.map(val => val?.countryCode);
    setActiveCountry(countries);
  }else if(!getOnboardingStatusLoading && getOnboardingStatusError) {
    dispatch(errorMessage(getOnboardingStatusSuccess?.data?.message || "Error occurred while performing this action"))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [getOnboardingStatusLoading]);

  return (
      <>
        {open && (
          <CustomModal
            openModal={open}
            setOpenModal={() => setOpen(false)}
          >
            <div className="onboarding-modal-container">
              {feedBack?.success && onSuccess(feedBack?.message)}
              {feedBack?.error && onError(feedBack?.message)}
              <div className="choose-country">Choose Country <span className="cursor" onClick={()=>setOpen(false)}><CancelIcon/></span></div>
              <div className="available">See available countries you can onboard.</div>
              <div className="search-input">
                  <span className="search-icon"><SearchIcon/></span>
                  <input className="form-control" placeholder="Search" onChange={onFilter}/>
              </div>
              <div className="continue">
                  Choose any country to continue.
              </div>
              {onBoardingLoading?<div className="content-loading">
                <CustomSpinner height="75vh" label="Loading" />
              </div>:<>{
                country?.map(val => (
                   <div key={val?.countryCode} className={!activeCountry || activeCountry?.indexOf(val?.countryCode) < 0 ?'country':'country-active'} onClick={()=>onBoard(val?.countryCode)}><span>{val?.countryName} {activeCountry?.indexOf(val?.countryCode) > -1? <span className="pending">Pending</span>:<span className="active">Available</span>}</span><ArrowRight/></div>
                ))
              }</>}
              
            </div>
          </CustomModal>
        )}
        {notApprovedCountry && <AwaitingApprovalPage notApprovedCountry={notApprovedCountry} description="Your onboarding request has been successfully submitted and feedback will be provided shortly." setOpenSuccess={setOpenSuccess} setOpen={setOpen}/> }
      </>
  );
}

export default OnboardModal;