import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux"
import { LoadingTableContent } from "../../../components/loader/Loader";
import _debounce from "lodash.debounce";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Table from "vgg-ui-react/dist/components/Core/Table";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "../settlements.scss";
import TablePagination from "../../../shared/tablePagination/tablePagination";
import ViewModal from "../../../components/viewModal/ViewModal";
import DeleteModal from "../../../components/modal/DeleteModal";
import { deleteSettlementSubAccount, getSettlementSubAccount } from "../../../redux/actions/action";
import Form from "./form/form";
import { MoreIconIcon } from "../../../components/icons";
import { errorMessage, successMessage } from "../../../redux/actions/notification";
import EmptyState from "../../../shared/empty/empty";

type viewPayload = {
  title: string,
  value: string
}

const SubAccount = () => {
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [selectedRow, setSelectedRow] = useState(false);
  const [show, setShow] = useState(0);
  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [payloadData, setPayloadData] = useState({});
  const [viewDetail] = useState<Array<viewPayload>>();
  const [openSubAccountModal, setOpenSubAccountModal] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  
  const { getSettlementSubAccountSuccess, getSettlementSubAccountLoading, deleteSubAccountSuccess, deleteSubAccountError, deleteSubAccountLoading} = useSelector(({ reducer }:any) => {
    return {
      deleteSubAccountSuccess: reducer?.deleteSubAccountSuccess,
      deleteSubAccountError: reducer?.deleteSubAccountError,
      deleteSubAccountLoading: reducer?.deleteSubAccountLoading,
      getSettlementSubAccountSuccess: reducer?.getSettlementSubAccountSuccess,
      getSettlementSubAccountLoading: reducer?.getSettlementSubAccountLoading,
      getSettlementSubAccountError: reducer?.getSettlementSubAccountError,
    };
  });
  
  const handleSearch = _debounce((event:React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, 1000);

  const onEdit = (val:any) => {
      setEdit(true);
      setId(val.id)
      setDeleteItem(false);
      setPayloadData(val)
      setOpenSubAccountModal(true)
  }
  const onDelete = (val:any) => {
    setId(val.id)
    setEdit(false);
      setDeleteItem(true);
      setPayloadData(val)
  }

  const onConfirmDelete = () => {
    setDeleteStatus(true);
    dispatch(deleteSettlementSubAccount(id))
  }

  useEffect(()=>{
    dispatch(getSettlementSubAccount(search, page.pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageNumber, search])

  useEffect(()=> {
    if(deleteSubAccountSuccess?.data?.requestSuccessful && !deleteSubAccountLoading && deleteStatus) {
      setDeleteItem(false);
      setDeleteStatus(false);
      dispatch(successMessage("Sub-Account deletion was successful"))
      dispatch(getSettlementSubAccount());
    }else if(deleteSubAccountError && !deleteSubAccountLoading && deleteStatus) {
      setDeleteStatus(false);
     dispatch(errorMessage(deleteSubAccountError?.data?.message|| "Unable to delete Sub-Account, please try again"))
    }else if(deleteSubAccountSuccess?.data?.requestSuccessful===false && !deleteSubAccountLoading && deleteStatus) {
      setDeleteStatus(false);
     dispatch(errorMessage(deleteSubAccountSuccess?.data?.message|| "Unable to delete Sub-Account, please try again"))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [deleteSubAccountLoading])
 
  return (
    <>
      <div className="mx-3 mt-3 d-flex align-items-center py-2">
        <Typography typographyVariant="section-title" text="Sub Account Created" className="font-weight-500" />
      </div>
      <hr className="separate-line" />
      <div className="table-items">
          <div className="search-container">
            <input type="number" placeholder="Search with account number" className="form-control" onChange={handleSearch} />
            <i className="ri-search-line"></i>
          </div>
          <>
          <Button
            label="+ Add Sub Account"
            className="open-btn"
            background="blue"
            size="md"
            onClick={() => setOpenSubAccountModal(true)}
          />
          </>
        
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Account Number</th>
              <th>Bank Name</th>
              <th>Country Code</th>
              <th>Action</th>
            </tr>
          </thead>
          {getSettlementSubAccountLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={5} />
            </tbody>
          ) : (
            <tbody>
                  {getSettlementSubAccountSuccess?.data?.responseData?.data?.map(val =>(<tr key={val}>
                    <td>{val?.accountName}</td>
                    <td>{val?.accountNumber}</td>
                    <td>{val?.bankName}</td>
                    <td>{val?.countryCode}</td>
                    <td><div className="options" onMouseLeave={()=>setShow(0)}  onMouseEnter={()=>setShow(val.id)}><MoreIconIcon/>
                      {show === val.id && <div className="more-options">
                         <div className="option" onClick={()=>onEdit(val)}>Edit</div>
                         <div className="option" onClick={()=>onDelete(val)}>Delete</div>
                      </div>}
                    </div></td>
                  </tr>))}
            </tbody>
          )}
        </table>
        {getSettlementSubAccountSuccess?.data?.responseData?.data?.length === 0 && <EmptyState />}
        </div>
        <hr className="separate-line" />
          {getSettlementSubAccountSuccess?.data?.responseData?.data?.length > 0 && <div>
            <TablePagination
              totalCount={getSettlementSubAccountSuccess?.data?.responseData?.totalItems}
              currentPage={getSettlementSubAccountSuccess?.data?.responseData?.pageNumber}
              pageSize={getSettlementSubAccountSuccess?.data?.responseData?.pageSize}
              onPageChange={p => setPage(prev => ({ ...prev, pageNumber: p }))}
              totalPages={getSettlementSubAccountSuccess?.data?.responseData?.totalPages}
            />
          </div>}
      <ViewModal
        title="Settlement Details"
        data={viewDetail}
        toggle={() => setSelectedRow(!selectedRow)}
        open={!!selectedRow}
      />
         <Form openSubAccountModal={openSubAccountModal} setOpenSubAccountModal={setOpenSubAccountModal} edit={edit} data={payloadData}/>
       <DeleteModal loading={deleteSubAccountLoading} openModal={deleteItem} setOpenModal={setDeleteItem} title="Delete Sub Account" onDelete={onConfirmDelete}/>
     
    </>
  );
};

export default SubAccount;
