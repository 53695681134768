import React from "react";
import CustomModal from "./Modal";
import {CancelIcon} from "../icons";
import "./modal.scss";
import ButtonWrapper from "../button/Button";

type Props = {
    title: string,
    loading?: boolean,
    onDelete?: any,
    btnBackground?: string,
    label?: string,
    description?: string,
    openModal: boolean,
    setOpenModal: any
}

const DeleteModal: React.FC<Props> = ({title, label, btnBackground, description, openModal, setOpenModal, loading, onDelete }) =>{
    return (
        <CustomModal
        openModal={openModal}
        setOpenModal={() => setOpenModal(!openModal)}
      >
        <div className="delete-modal-container">
          <div className="modal-title">{title} <span className="cursor" onClick={()=>setOpenModal(false)}><CancelIcon /></span></div>
          <div className="desc">{description || "Are you sure about this, you could lose some information"}</div>
          <div className="btns">
              <span className="cancel-btn" onClick={()=>setOpenModal(false)}>Cancel</span>
              <ButtonWrapper
            label={label||"Delete"}
            className="delete-btn"
            background={btnBackground||"danger"}
            size="md"
            width="100%"
            loading={loading}
            onClick={onDelete}
          />
          </div>
        </div>
      </CustomModal>

    )
}

export default DeleteModal