import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import OnboardingPage from "../onboardingPage/OnboardingPage";
import Dashboard from "../dashboard/Dashboard";
import { errorMessage } from "../../redux/actions/notification";
import { getCountry, getOnboardingStatus, getSupportedCountry } from "../../redux/actions/action";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import ErrorPage from "../../components/ErrorPage/ErrorPage";

const LandingPage = () => {
  const dispatch = useDispatch();
  
  const { getOnboardingStatusSuccess, getOnboardingStatusLoading, getOnboardingStatusError} = useSelector(({ reducer }:any) => {
    return {
      getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
      getOnboardingStatusLoading: reducer?.getOnboardingStatusLoading,
      getOnboardingStatusError: reducer?.getOnboardingStatusError,
      getCountrySuccess: reducer?.getCountrySuccess,
      getCountryLoading: reducer?.getCountryLoading,
      getCountryError: reducer?.getCountryError,
    };
  });
  
  useEffect(()=>{
    dispatch(getOnboardingStatus());
    dispatch(getCountry());
    dispatch(getSupportedCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  useEffect(()=>{
    if(!getOnboardingStatusLoading && getOnboardingStatusError) {
      dispatch(errorMessage(getOnboardingStatusSuccess?.data?.message || "Error occurred while performing this action"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnboardingStatusLoading]);

  const renderComponent = (onboarded: boolean) => {
    if (onboarded) {
       return <Dashboard />;
    } else {
      return <OnboardingPage />;
    }
  };

  if (getOnboardingStatusLoading) {
    return <CustomSpinner height="75vh" />;
  }else if (!getOnboardingStatusLoading && (getOnboardingStatusSuccess?.data.requestSuccessful ===false|| getOnboardingStatusError)) {
    return <ErrorPage />;
  }else if(getOnboardingStatusSuccess?.data.requestSuccessful){
    return renderComponent(getOnboardingStatusSuccess?.data?.responseData?.isActive);
  }
  return null
  
};

export default LandingPage;
