import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import rootReducer from "./reducers/rootReducer";

const initialState = {};

const middleWare = [thunk];

const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleWare, promise), (typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION__) ? (typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION__)() : (f: any) => f));

export { store };
