import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { CancelIcon } from "../icons";
import "./viewModal.scss";

type Props = {
  title: string,
  data: Array<{title: string, value: string|null}>|undefined,
  toggle: () => void,
  open: boolean
}
const Item = ({ label, value }) => {
 return <div className="item">
    <Typography typographyVariant="caption" text={label} className="me-3 mb-3 align-self-start" />
    <Typography typographyVariant="text1" text={value || "--"} className="me-3 align-self-start" />
  </div>
}

const ViewModal: React.FC<Props> = ({ title, open, toggle, data }) => {
  return (
    <div>
    <Modal isOpen={open} toggle={toggle} className="view-detail-container drawer fadeInRight w-480 animated ml-auto">
      <ModalBody>
        <div className="detail-title">{title} <span className="cancel" onClick={toggle}><CancelIcon/></span></div>
        <div className="detail-content">
          {data?.map((val, i) =>(<Item key={i} label={val.title} value={val.value} />))}
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
    </div>
  );
};

export default ViewModal;
