import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuccessIcon from "../../assets/images/success.svg";
import WarningIcon from "../../assets/images/warning.svg";
import ErrorIcon from "../../assets/images/error.svg";
import SuccessMgIcon from "../../assets/images/success.svg";
import ErrorMgIcon from "../../assets/images/error.svg";

import Icon from "./icons";
import "./notifications.scss";
import { closeMessage } from "../../redux/actions/notification";
import Icons from "./icons";


export const onSuccess = (message, closeMsg) => {
  return <div className="notification-container">
    <div className="success">
     <img src={SuccessMgIcon} alt="success icon" />
     {message}
     <span className="cursor" onClick={closeMsg}>{Icons.onboarding("#1E944D")}</span>
   </div>
   </div>
}

export const onError = (message, closeMsg) => {
  return <div className="notification-container">
    <div className="error">
  <img src={ErrorMgIcon} alt="error icon" />
   {message}
  <span className="cursor" onClick={closeMsg}>{Icons.onboarding("#CA1B1B")}</span>
</div>
</div>
}


const Notification = () => {
  const dispatch = useDispatch();

  const { success, warning, error } = useSelector(({ notification }: any) => {
    return {
      success: notification?.successMessage,
      warning: notification?.warningMessage,
      error: notification?.errorMessage,
    };
  });
  useEffect(() => {
    setTimeout(() => dispatch(closeMessage()), 1500000000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, warning, error]);
  return (
    <>
      {success && (
        <div className="notification-container">
          <div className="success">
            <img src={SuccessIcon} alt="success icon" />
            {success}
            <span onClick={() => dispatch(closeMessage())}>{Icon.onboarding("#1E944D")}</span>
          </div>
        </div>
      )}
      {warning && (
        <div className="notification-container">
          <div className="warning">
            <img src={WarningIcon} alt="warning icon" />
            {warning}
            <span onClick={() => dispatch(closeMessage())}>{Icon.onboarding("#D66F0F")}</span>
          </div>
        </div>
      )}
      {error && (
        <div className="notification-container">
          <div className="error">
            <img src={ErrorIcon} alt="error icon" />
            {error || "Error occurred while processing this request"}
            <span onClick={() => dispatch(closeMessage())}>{Icon.onboarding("#CA1B1B")}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
