import React, {useState, useEffect} from "react";
import Select from 'react-select'
import { format } from "date-fns";

import {useDispatch, useSelector} from "react-redux";
import { Row, Col } from "reactstrap";
import { Line } from "react-chartjs-2";
import {TransactionHistoryChartOptions} from "../../shared/constants/constant"
import { AvailableIcon, CountIcon, LedgerIcon } from "../../components/icons";
import TransactionIcon from "../../components/icons/count";
import LineChart from "./graph/LineGraph";
import BarChart from "./graph/BarChart";
import DoughnutChart from "./graph/DoughnutChart";
import OnBoardingModal from "../onboardingPage/onboardModal/OnboardModal";
// import MapChart from "./graph/geoChart";

import "./overview.scss";
import { getCollectOverview, getCountry, getCurrencies } from "../../redux/actions/action";
import Loader, { PageLoader } from "../../components/loader/Loader";
import EmptyState from "../../shared/empty/empty";

const PeriodicOptions = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' }
]

const Overview = () => {
   const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isChartData, setIsChartData] = useState<any>(false);
  const [chartData, setChartData] = useState<any>({});
  const [selectedCurrency, setselectedCurrency] = useState<any>("");
  const [selectedPeriod, setSelectedPeriod] = useState<any>({label:"Daily", value: "Daily"});

  const { currenciesPayload, collectOverviewLoading, collectOverviewSuccess, getOnboardingStatusSuccess} = useSelector (({ reducer }:any) => {
   return {
      getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
     collectOverviewSuccess: reducer?.collectOverviewSuccess,
     collectOverviewLoading: reducer?.collectOverviewLoading,
     collectOverviewError: reducer?.collectOverviewError,
     currenciesPayload: reducer?.currenciesPayload

   };
 });

 const onSelectPeriod = (e: any) => {
    setSelectedPeriod(e)
    dispatch(getCollectOverview(e.value, selectedCurrency?.value));
 }

 const onSelectCurrency = (e: any) => {
   setselectedCurrency(e)
    dispatch(getCollectOverview(selectedPeriod?.value, e.value));
 }

  useEffect(()=>{
    dispatch(getCollectOverview(selectedPeriod?.value, selectedCurrency?.value));
    dispatch(getCurrencies());

    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 useEffect(()=> {
   if(!collectOverviewLoading && collectOverviewSuccess) {
      let items: any = [];
      let labels: any = []
      for(let val in collectOverviewSuccess?.data?.TransactionCount) {
         console.log({vol: Number(collectOverviewSuccess?.data?.TransactionCount[val])})
         const count = Number(collectOverviewSuccess?.data?.TransactionCount[val])
         if(count !== 0) {
            setIsChartData(true)
         }
          items.push(count);
          labels.push(val)
      }
 
      setChartData({
         labels,
         datasets: [
           {
             label: "Deposit",
             data: items,
             borderColor: "#175FFF",
             borderWidth: 2,
             backgroundColor: "rgba(255, 99, 132, 0.5)",
             tension: 0.3,
           },
         ],
       });
   }
 }, [collectOverviewLoading])

  return (
    <div className="collection-overview-container">
      <div className="top-layer">
        <div className="analytics">Analytics</div>
        <div className="options">
        <div><Select placeholder="Currency" options={currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))} value={selectedCurrency} onChange={onSelectCurrency} /></div>
        <div><Select options={PeriodicOptions} value={selectedPeriod} onChange={onSelectPeriod} className="option"/></div>
        <div>
        <button
            className="onboard-country"
            onClick={()=>setOpen(true)}
          >Onboard Country</button>
        </div>
        </div>
      </div>

      <Row>
        <Col xs="6" sm="6" md="3" lg="3" >
          <div>
              <div className="card-content transaction-value">
                 <div>
                     <div className="card-title">Total Transaction Value</div>
                     <div className="card-desc">{collectOverviewSuccess?.data?.TotalTransactionVolume}</div>
                 </div>
                 <TransactionIcon/>
              </div>
           </div>
        </Col>
        <Col xs="6" sm="6" md="3" lg="3" >
          <div>
              <div className="card-content transaction-count">
                 <div>
                     <div className="card-title">Total Transaction Count</div>
                     <div className="card-desc">{collectOverviewSuccess?.data?.TotalTransactionCount}</div>
                 </div>
                 <CountIcon/>
              </div>
           </div>
        </Col>
       <Col xs="6" sm="6" md="3" lg="3" >
          <div>
              <div className="card-content  available-balance">
                 <div>
                     <div className="card-title">Available Balance</div>
                     <div className="card-desc">{collectOverviewSuccess?.data?.AvaiableBalance}</div>
                 </div>
                 <AvailableIcon />
              </div>
           </div>
        </Col>
        <Col xs="6" sm="6" md="3" lg="3" >
          <div>
              <div className="card-content ledge-balance">
                 <div>
                     <div className="card-title">Ledger Balance</div>
                     <div className="card-desc">{collectOverviewSuccess?.data?.LedgerBalance}</div>
                 </div>
                 <LedgerIcon />
              </div>
           </div>
        </Col>

        
        
      </Row>
      <Row>
          <Col>
          <div className="graph-container">
          <div className="title">Transaction Report</div>
             <div className="chart">
             {!collectOverviewLoading && isChartData ? (
                      <Line
                        options={TransactionHistoryChartOptions(selectedPeriod?.value)}
                        data={chartData}
                      />
                    ) : (
                      <div className="empty h-100 d-flex align-items-center justify-content-center">
                        <div className="no-data__text">
                          {collectOverviewLoading ? (
                            <PageLoader height="35vh" width="100%" />
                          ) : (
                            !isChartData && <EmptyState />
                          )}
                        </div>
                      </div>
                    )}
                    </div>
          </div>
          </Col>
      </Row>
      <Row>
          <Col md="6">
          <div className="graph-container">
          <div className="title">Distribution by Channel</div>
             <div className="chart"><BarChart data={collectOverviewSuccess?.data?.AmountChannel}/></div>
          </div>
          </Col>
          <Col md="6">
          <div className="graph-container">
          <div className="title">Status Percentage</div>
             <div className="chart"><DoughnutChart data={collectOverviewSuccess?.data?.StatusPercentage}/></div>
          </div>
          </Col>
      </Row>
      <OnBoardingModal open={open} setOpen={setOpen}/>   

    </div>
  );
};

export default Overview;
