import React, { useState } from "react";
import Select from 'react-select';
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import { LoadingTableContent } from "../../../components/loader/Loader";
import _debounce from "lodash.debounce";
import Table from "vgg-ui-react/dist/components/Core/Table";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "../settlements.scss";
import TablePagination from "../../../shared/tablePagination/tablePagination";
import ViewModal from "../../../components/viewModal/ViewModal";
import { useEffect } from "react";
import { getCurrencies, getSettlementOverview } from "../../../redux/actions/action";
import EmptyState from "../../../shared/empty/empty";
import { DateFormat } from "../../../shared/constants/constant";

type viewPayload = {
  title: string,
  value: string
}
const Overview = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [selectedRow, setSelectedRow] = useState(false);
  const [viewDetail, setViewDetail] = useState<Array<viewPayload>>();
  const [search, setSearch] = useState("");
  const [currencySearch, setCurrencySearch] = useState<any>();
  
  const { currenciesPayload, getSettlementOverviewSuccess, getSettlementOverviewLoading} = useSelector(({ reducer }:any) => {
    return {
      getSettlementOverviewSuccess: reducer?.getSettlementOverviewSuccess,
      getSettlementOverviewLoading: reducer?.getSettlementOverviewLoading,
      getSettlementOverviewError: reducer?.getSettlementOverviewError,
      currenciesPayload: reducer?.currenciesPayload
    };
  });
 
 
  const handleSearch = _debounce((event:React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, 1000);

  const view = (val: any) => {
    const details = [
      {title: "Transaction Reference", value: val?.transaction?.transactionReference}, 
      {title: "Settlement Type", value: val?.settlementPreference}, 
      {title: "Amount", value: `${val?.amount}(${val?.currency})`},
      {title: "Settlement Status", value: val?.settlementStatus},
      {title: "Date Created", value: DateFormat(val?.postedDate)},
      {title: "Batch Id", value: val?.batchCode},
      {title: "Settlement Account Number", value: val?.settlementBankDetails?.accountNumber},
      
    ]
    setViewDetail(details);
    setSelectedRow(true)
  }

  useEffect(()=> {
    dispatch(getSettlementOverview(search,currencySearch?.value, page.pageNumber))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageNumber, search, currencySearch]);

  useEffect(()=> {
    dispatch(getCurrencies());
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <>
      <div className="mx-3 mt-3 d-flex align-items-center py-2"> 
        <Typography typographyVariant="section-title" text="Settlements" className="font-weight-500" />
      </div>
      <hr className="separate-line" />
      <div className="table-items">
          <div className="search-container">
            <input type="number" placeholder="Search for account number" className="form-control" onChange={handleSearch} />
            <i className="ri-search-line"></i>
          </div>
          <>
          <Select placeholder="Currency" options={currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))} value={currencySearch} onChange={(e)=>setCurrencySearch(e)} />
          </>
        
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Currency</th>
              <th>Account Settled</th>
              <th>Settlement Type</th>
              <th>Batch ID</th>
              <th>Status</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          {getSettlementOverviewLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={7} />
            </tbody>
          ) : (
            <tbody>
                  {getSettlementOverviewSuccess?.data?.responseData?.data?.map(val =>(<tr key={val}>
                    <td>{val?.amount}</td>
                    <td>{val?.currency}</td>
                    <td>{val?.settlementBankDetails?.accountNumber || "--"}</td>
                    <td>{val?.settlementPreference}</td>
                    <td>{val?.batchCode || "--"}</td>
                    <td>{val?.settlementStatus}</td>
                    <td>{DateFormat(val?.postedDate) || "--"}</td>
                    <td>
                      <div className="action"  onClick={()=>view(val)}>
                        View
                      </div>
                    </td>
                  </tr>))}
            </tbody>
          )}
        </table>
        {!getSettlementOverviewLoading && getSettlementOverviewSuccess?.data?.responseData?.data?.length === 0 && <EmptyState />}
        </div>
        <hr className="separate-line" />
      {getSettlementOverviewSuccess?.data?.responseData?.data?.length > 0 && <div>
          <div>
            {getSettlementOverviewSuccess && <TablePagination
              totalCount={getSettlementOverviewSuccess?.data?.responseData?.totalItems}
              currentPage={getSettlementOverviewSuccess?.data?.responseData?.pageNumber}
              pageSize={getSettlementOverviewSuccess?.data?.responseData?.pageSize}
              onPageChange={p => setPage(prev => ({ ...prev, pageNumber: p }))}
              totalPages={getSettlementOverviewSuccess?.data?.responseData?.totalItems}
            />}
          </div>
      </div>}
      <ViewModal
        title="Settlement Details"
        data={viewDetail}
        toggle={() => setSelectedRow(!selectedRow)}
        open={!!selectedRow}
      />
    </>
  ); 
};

export default Overview;
