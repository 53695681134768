import React, { useState, useEffect } from "react";
import Select from 'react-select';
import {useSelector, useDispatch} from "react-redux";
import "../../settlements.scss";
import Button from "../../../../components/button/Button";
import CustomModal from "../../../../components/modal/Modal";
import { CancelIcon } from "../../../../components/icons";
import { getSettlementProfile, postSettlementProfile } from "../../../../redux/actions/action";
import { errorMessage, successMessage } from "../../../../redux/actions/notification";

type PropsType = {
  openAccountModal: boolean,
  openProfileModal: boolean,
  setOpenProfileModal: any,
   setOpenAccountModal: any,
   edit: boolean,
   data: any,
   id?:number,
   setPayload: any,
   payload:any,
   submitStatus: boolean,
   setSubmitStatus: any
}
const Profile:React.FC<PropsType> = ({submitStatus, setSubmitStatus, payload, setPayload,id, data, openProfileModal, edit,  setOpenProfileModal, openAccountModal, setOpenAccountModal}) => { 
  const [subPayload, setSubPayload] = useState<{subAccountId?:number, ratioType?:string, ratio?:number, subAccountsArr?:any}>();
  const [submitSubStatus, setSubmitSubStatus] = useState<boolean>();
  const [subIndex, setSubIndex] = useState(-1);
  const dispatch = useDispatch();
  
  const { postSettlementProfileSuccess, postSettlementProfileLoading, getSettlementSubAccountSuccess} = useSelector(({ reducer }:any) => {
    return {
        getSettlementSubAccountSuccess: reducer?.getSettlementSubAccountSuccess,
      postSettlementProfileSuccess: reducer?.postSettlementProfileSuccess,
      postSettlementProfileLoading: reducer?.postSettlementProfileLoading,
      postSettlementProfileError: reducer?.postSettlementProfileError,
    };
  });
 
  const onSelectSubAccount = (e: any) =>{
    setSubPayload({...subPayload, subAccountId: e.value , subAccountsArr: e })
  }
  
  const onOpenAccountModal = () => {
    setSubmitSubStatus(false);
    setSubIndex(-1)
    setSubPayload({})
    setOpenAccountModal(true);
    setOpenProfileModal(false);
  }

  const onSubmit = () => {
    setSubmitStatus(true)
    if(payload?.name && payload?.mainAccountId) {
       dispatch(postSettlementProfile(payload, id))
    }
  }

  const removeSubAccount =(index: number) => {
     const subAccount = payload?.subAccounts;
     subAccount?.splice(index, 1)
     setPayload({...payload, subAccounts:subAccount} ) 
  }
 
  const onCloseAccountModal = () => {
    setSubmitSubStatus(true);
    if(subPayload?.subAccountId && subPayload?.ratioType && subPayload?.ratio) {
      if(subPayload?.ratio < 0) {
        dispatch(errorMessage("Your sharing ratio must be a positive number"))
        return
      }
      if(subIndex < 0){
        setPayload({...payload, subAccounts: [...payload?.subAccounts||[], subPayload]})
      }else {
        let accounts = payload?.subAccounts||[];
        accounts[subIndex] = subPayload;
        setPayload({...payload, subAccounts: [...accounts||[]]})
      }
      setOpenAccountModal(false);
      setOpenProfileModal(true);
    }
  }

  const onEditSubAccount = (value: any, i: number) => {
    setSubIndex(i)
    setSubPayload({subAccountId: value?.subAccountId, ratioType:value.ratio, subAccountsArr:value.subAccountsArr
    })
    setOpenAccountModal(true)
    setOpenProfileModal(false)
  }

  const closeSubAccountModal = () => {
    if(openAccountModal){
      setOpenAccountModal(false);
      setOpenProfileModal(true)
    }else{
      setOpenProfileModal(false);
      setOpenAccountModal(true)
    }
  }

  useEffect(()=>{
    if(edit) {
      setPayload({subAccount: {label: data?.mainAccountName, value: data?.mainAccountId}, name:data?.name, subAccounts: data?.subAccounts?.map(subVal => ({subAccountsArr:{label:subVal?.accountName, value: subVal?.subAccountId}, subAccountId: subVal?.subAccountId, ratioType: subVal?.ratio, ratio: subVal?.ratio})), mainAccountId: data.mainAccountId})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])

  useEffect(()=>{
    if(openProfileModal) {
      setSubPayload({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openProfileModal])

  useEffect(()=> {
    if(postSettlementProfileSuccess?.data?.requestSuccessful && !postSettlementProfileLoading && submitStatus) {
        setSubmitStatus(false);
        dispatch(successMessage(`Profile ${id?"edition":"creation"} was successful`));
        dispatch(getSettlementProfile());
        setOpenProfileModal(false);
        setPayload({});
    }else if(!postSettlementProfileSuccess?.data?.requestSuccessful && !postSettlementProfileLoading && submitStatus) {
        setSubmitStatus(false)
        dispatch(errorMessage(postSettlementProfileSuccess?.data?.message|| `Unable to ${id?"edit":"create"} profile, please try again`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [postSettlementProfileLoading])

  return (
    <div>
      <CustomModal
            openModal={openAccountModal}
            setOpenModal={closeSubAccountModal}
          >
            <div className="add-modal-container">
              <div className="modal-title">{id?"Update":"Add"} Account <span className="cursor" onClick={closeSubAccountModal}><CancelIcon/></span></div>
              <div className="form-group">
                <label htmlFor="sub-account">Sub Account</label>
                <Select options={getSettlementSubAccountSuccess?.data?.responseData?.data?.map(val =>({value: val?.id, label: val?.accountName}))} onChange={e => onSelectSubAccount(e)} value={subPayload?.subAccountsArr} />
              </div>
              {submitSubStatus && !subPayload?.subAccountId && <div className="error-message">Sub Account is required</div>}

              <div className="form-group">
                <label htmlFor="sub-account">Sharing Type</label>
                <select className="form-control" onChange={(e)=>setSubPayload({...subPayload, ratioType: e.target.value})} value={subPayload?.ratioType}>
                    <option></option>
                    <option>Flat</option>
                    <option>Percentage</option>
                </select>
              </div>
              {submitSubStatus && !subPayload?.ratioType && <div className="error-message">Sharing Type is required</div>}
              
              <div className="form-group">
                <label htmlFor="sub-account">Sharing Ratio</label>
                <input className="form-control" type="number" value={subPayload?.ratio} onChange={(e) => setSubPayload({...subPayload, ratio: Number(e.target.value)})}/>
              </div>
              {submitSubStatus && !subPayload?.ratio && <div className="error-message">Sharing Ratio is required</div>}
             
              <div>
                  <Button
                label="Save"
                className="open-btn"
                background="primary"
                size="md"
                width="100%"
                onClick={onCloseAccountModal}
              />
              </div>
            </div>
          </CustomModal>

          <CustomModal
            openModal={openProfileModal}
            setOpenModal={setOpenProfileModal}
          >
            <div className="add-modal-container">
              <div className="modal-title">{id?"Edit":"New"} Profile <span className="cursor" onClick={()=>setOpenProfileModal(false)}><CancelIcon/></span></div>
              <div className="form-group">
                <label htmlFor="sub-account">Name</label>
                <input className="form-control" value={payload?.name} onChange={e=> setPayload({...payload, name:e.target.value})} type="text"/>
              </div>
              {submitStatus && !payload?.name && <div className="error-message">Name is required</div>}
              
              <div className="form-group">
                <label htmlFor="sub-account">Main Account</label>
                <Select options={getSettlementSubAccountSuccess?.data?.responseData?.data?.map(val =>({value: val?.id, label: val?.accountName}))} onChange={e => setPayload({...payload, mainAccountId: Number(e.value), subAccount: e })} value={payload?.subAccount} />
              </div>
              {submitStatus && !payload?.mainAccountId && <div className="error-message">Main Account is required</div>}
               
              <div className="form-group">
                <label htmlFor="sub-account">Share of Rate</label>
                <input className="form-control" value="100%" disabled type="text"/>
              </div>
              
              {payload?.subAccounts?.map((val, i) => (<div key={val?.subAccountsArr?.label+i} onClick={()=>onEditSubAccount(val, i)}  className="form-group">
                <label htmlFor="account" className="subtitle">Account {i+1} <span className="cursor" onClick={()=>removeSubAccount(i)}><CancelIcon/></span></label>
                <div className="account-detail">
                  <div className="name">Sub-Account - {val?.subAccountsArr?.label} </div>
                  <div className="number">Ratio Type - {val?.ratioType}</div>
                  <div className="number">Ratio - {val?.ratio}</div>
                </div>
              </div>))}
              <div>
                  <Button
                label="Add Account"
                className="add-account-btn"
                size="md"
                width="100%"
                onClick={onOpenAccountModal}
              />
              </div> 
              <div>
                  <Button
                label={id?"Update":"Finish"}
                className="open-btn"
                background="primary"
                size="md"
                width="100%"
                onClick={onSubmit}
                loading={postSettlementProfileLoading}
              />
              </div>
            </div>
          </CustomModal>
          </div>

  );
};

export default Profile;
