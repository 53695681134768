import { userDetails } from "./user.service";
declare var window: any;

// https://afservicemanagerclienttest.azurewebsites.net
export const virtualAccountUrl =
  process.env.NODE_ENV === "development"
    ? "https://virtualaccount-apitest.azurewebsites.net/api"
    : `${window.env?.virtualAccountBaseUrl}/api`;
export const collectionUrl =
  process.env.NODE_ENV === "development"
    ? "https://afcollectionaggregatortest.azurewebsites.net/api"
    : `${window.env?.collectionApiUrl}/api`;

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.baseApiUrl}/api`;

export const serviceManager =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagertest.azurewebsites.net/api"
    : `${window.env?.serviceManagerApiUrl}/api`;


export const instanceUrl =
  process.env.NODE_ENV === "development"
    ? "https://vgnpcdisagrservicetest.azurewebsites.net/api"
    : `${window.env?.instanceApiUrl}/api`;

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return { headers: { Authorization: "" } };
};
