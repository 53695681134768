import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { downloadTransaction } from "../../../redux/actions/action";
import { errorMessage } from "../../../redux/actions/notification";
import "./exportModal.scss";

const ExportModal = ({
  show,
  toggleModal
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [endDate, setEndDate] = useState(null);
 
  const { downloadTransactionSuccess,downloadTransactionError, downloadTransactionLoading} = useSelector(({ reducer }:any) => {
    return {
      downloadTransactionSuccess: reducer?.downloadTransactionSuccess,
      downloadTransactionLoading: reducer?.downloadTransactionLoading,
      downloadTransactionError: reducer?.getTransactionError,
    };
  });
 
  const onSubmit = () => {
    setSubmitStatus(true);
    if(startDate && endDate){
      dispatch(downloadTransaction(startDate, endDate))
    }
  }

  useEffect(()=>{
    if(!downloadTransactionLoading && downloadTransactionSuccess && submitStatus) {
      const url = window.URL.createObjectURL(new Blob([downloadTransactionSuccess?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      setStartDate(null)
      setEndDate(null)
    setSubmitStatus(false);
      toggleModal()
    }else if(!downloadTransactionLoading && downloadTransactionError && submitStatus) {
    setSubmitStatus(false);
      dispatch(
        errorMessage(
          downloadTransactionError?.data?.message || "Error occurred while downloading"
        )
      );
    }
  }, [downloadTransactionLoading])
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="md"
      className="export-modal-container"
    >
      <ModalHeader toggle={toggleModal}>
         Export Transactions Log
      </ModalHeader>
      <ModalBody className="modal__body">
      <form className="create-form">
              <div>
                <label id="start">Start Date</label>
                <input onChange={(e:any)=>setStartDate(e.target.value)} type="date" className="form-control"/>
              </div>
              {submitStatus && !startDate && <div className="error-desc">Start date is required</div>}

              <div>
                <label id="end">End Date</label>
                <input onChange={(e:any)=>setEndDate(e.target.value)} type="date" className="form-control"/>
              </div>
              {submitStatus && !endDate && <div className="error-desc">End date is required</div>}

             </form>
             <div>
             <Button
          color="primary"
          onClick={onSubmit}
          className="submit-btn"
          disabled={downloadTransactionLoading}
        >
          {!downloadTransactionLoading?"Download":<Spinner size="sm"/>}
        </Button>
             </div>
      </ModalBody>
      </Modal>
  );
};

export default ExportModal;
