import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { LoadingTableContent } from "../../../components/loader/Loader";
import _debounce from "lodash.debounce";
import Table from "vgg-ui-react/dist/components/Core/Table";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "../settlements.scss";
import TablePagination from "../../../shared/tablePagination/tablePagination";
import ViewModal from "../../../components/viewModal/ViewModal";
import DeleteModal from "../../../components/modal/DeleteModal";
import { deleteProfile, getSettlementProfile, getSettlementSubAccount } from "../../../redux/actions/action";
import Form from "./form/form";
import { MoreIconIcon } from "../../../components/icons";
import { errorMessage, successMessage } from "../../../redux/actions/notification";
import EmptyState from "../../../shared/empty/empty";

type viewPayload = {
  title: string,
  value: string
}

const Profile = () => {
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [show, setShow] = useState(0);
  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [payloadData, setPayloadData] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [payload, setPayload] = useState<{name?:string, subAccounts?: Array<any>, mainAccountId?: number, mainAccountRatio?:number, subAccount?:any, subAccountsArr?:any, ratioType?: string}>(); 
  
  const [selectedRow, setSelectedRow] = useState(false);
  const [viewDetail] = useState<Array<viewPayload>>();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
 
  const dispatch = useDispatch();
  
  const { deleteProfileSuccess, deleteProfileError, deleteProfileLoading, getSettlementProfileSuccess, getSettlementProfileLoading} = useSelector(({ reducer }:any) => {
    return {
      getSettlementSubAccountSuccess: reducer?.getSettlementSubAccountSuccess,
      getSettlementProfileSuccess: reducer?.getSettlementProfileSuccess,
      getSettlementProfileLoading: reducer?.getSettlementProfileLoading,
      getSettlementProfileError: reducer?.getSettlementProfileError,
      deleteProfileSuccess: reducer?.deleteProfileSuccess,
      deleteProfileLoading: reducer?.deleteProfileLoading,
      deleteProfileError: reducer?.deleteProfileError,
    };
  });
 
  const handleSearch = _debounce((event:React.ChangeEvent<HTMLInputElement>) => {
    // const search = event.target.value;
   }, 1000); 
  
  const onEdit = (val:any) => {
    setEdit(true);
    setId(val.id)
    setDeleteItem(false);
    setPayloadData(val)
    setOpenProfileModal(true)
}
const onDelete = (val:any) => {
  setId(val.id)
  setEdit(false);
  setDeleteItem(true);
  setPayloadData(val)
}

const onConfirmDelete = () => {
  setDeleteStatus(true);
  dispatch(deleteProfile(id))
}

const newProfile = () => {
  setEdit(false); 
  setId(0)
  setOpenProfileModal(true);
  setPayload({});
  setSubmitStatus(false)
}

  
  useEffect(()=>{
      dispatch(getSettlementSubAccount("", 1, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
      dispatch(getSettlementProfile(page.pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageNumber])

  useEffect(()=> {
    if(deleteProfileSuccess?.data?.requestSuccessful && !deleteProfileLoading && deleteStatus) {
      setDeleteItem(false);
      setDeleteStatus(false);
      dispatch(successMessage("Profile deletion was successful"))
      dispatch(getSettlementProfile());
    }else if(deleteProfileError && !deleteProfileLoading && deleteStatus) {
      setDeleteStatus(false);
     dispatch(errorMessage(deleteProfileError?.data?.message|| "Unable to delete Profile, please try again"))
    }else if(deleteProfileSuccess?.data?.requestSuccessful===false && !deleteProfileLoading && deleteStatus) {
      setDeleteStatus(false);
     dispatch(errorMessage(deleteProfileSuccess?.data?.message|| "Unable to delete Profile, please try again"))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [deleteProfileLoading])
 

  return (
    <>
      <div className="mx-3 mt-3 d-flex align-items-center py-2">
        <Typography typographyVariant="section-title" text="Settlement Profile" className="font-weight-500" />
      </div>
      <hr className="separate-line" />
      <div className="table-items">
          <div className="search-container">
            {/* <input type="text" placeholder="Search" className="form-control" onChange={handleSearch} />
            <i className="ri-search-line"></i> */}
          </div>
          <>
          <Button
            label="+ New Profile"
            className="open-btn"
            background="blue"
            size="md"
            onClick={newProfile}
          />
          </>
        
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Main Account Name</th>
              <th>Profile Id</th>
              <th>Country</th>
              <th>Shared Ratio</th>
              <th>Number of Account</th>
              <th></th>
            </tr>
          </thead>
          {getSettlementProfileLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={5} />
            </tbody>
          ) : (
            <tbody>
                  {getSettlementProfileSuccess?.data?.responseData?.data?.map(val =>(<tr key={val.id}>
                    <td>{val?.mainAccountName}</td>
                    <td>{val?.profileId}</td>
                    <td>{val?.mainAccountCountry}</td>
                    <td>{val?.sharedRatio}</td>
                    <td>{val?.accountCount}</td>
                    <td><div className="options" onMouseLeave={()=>setShow(0)}  onMouseEnter={()=>setShow(val.id)}><MoreIconIcon />
                      {show === val.id && <div className="more-options">
                         <div className="option" onClick={()=>onEdit(val)}>Edit</div>
                         <div className="option" onClick={()=>onDelete(val)}>Delete</div>
                      </div>}
                    </div></td>
                  </tr>))}
            </tbody>
          )}
        </table>
         {getSettlementProfileSuccess?.data?.responseData?.data?.length === 0 && <EmptyState />}
        </div>
        <hr className="separate-line" />
      <div>
          <div>
          {getSettlementProfileSuccess?.data?.responseData?.data?.length > 0 && <TablePagination
              totalCount={getSettlementProfileSuccess?.data?.responseData?.totalItems}
              currentPage={getSettlementProfileSuccess?.data?.responseData?.pageNumber}
              pageSize={getSettlementProfileSuccess?.data?.responseData?.pageSize}
              onPageChange={p => setPage(prev => ({ ...prev, pageNumber: p }))}
              totalPages={getSettlementProfileSuccess?.data?.responseData?.totalPages}
            />}
          </div>
      </div>
      <ViewModal
        title="Settlement Details"
        data={viewDetail}
        toggle={() => setSelectedRow(!selectedRow)}
        open={!!selectedRow}
      />


          <Form submitStatus={submitStatus} setSubmitStatus={setSubmitStatus} id={id} edit={edit} setPayload={setPayload} payload={payload} data={payloadData} openProfileModal={openProfileModal} setOpenProfileModal={setOpenProfileModal} openAccountModal={openAccountModal} setOpenAccountModal={setOpenAccountModal}/>
          <DeleteModal loading={deleteProfileLoading} openModal={deleteItem} setOpenModal={setDeleteItem} title="Delete Sub Account" onDelete={onConfirmDelete}/>

    </>
  );
};

export default Profile;
