import React, { useState } from "react";
import Select from 'react-select'
import { LoadingTableContent } from "../../components/loader/Loader";
import _debounce from "lodash.debounce";
import moment from 'moment'
import Table from "vgg-ui-react/dist/components/Core/Table";

import "./transactions.scss";
import TablePagination from "../../shared/tablePagination/tablePagination";
import ViewModal from "../../components/viewModal/ViewModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCurrencies, getTransactions } from "../../redux/actions/action";
import EmptyState from "../../shared/empty/empty";
import ExportModal from "../../pages/transactions/exportModal/exportModal";
import WebhookNotification from "../../pages/transactions/webhookNotification/webhookNotification";
import { Button, Col, Row } from "reactstrap";
import { DateFormat } from "../../shared/constants/constant";
import { MoreIconIcon } from "../../components/icons";

type viewPayload = {
  title: string,
  value: string|null
}

const settlementStatuses:any = [
  {value:"", label:"Settlement Status"},
  {value:"Processing", label:"Processing"},
  {value:"Pending", label:"Pending"},
  {value:"Success", label:"Success"},
  {value:"Failed", label:"Failed"},
]
const transactionStatuses:any = [
  {value:"", label:"Transaction status"},
  {value:"Processing", label:"Processing"},
  {value:"Pending", label:"Pending"},
  {value:"Success", label:"Success"},
  {value:"Failed", label:"Failed"},
]

const Transactions = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [show, setShow] = useState(0);
  const [viewWebhook, setViewWebhook] = useState(false);
  const [reference, setReference] = useState("");
  const [openExport, setOpenExport] = useState(false)
  const [selectedRow, setSelectedRow] = useState(false);
  const [viewDetail, setViewDetail] = useState<Array<viewPayload>>();
  const [currencySearch, setCurrencySearch] = useState<any>();
  const [settlementStatus, setSettlementStatus] = useState<any>();
  const [transactionstatus, setTransactionstatus] = useState<any>();
  const [transactionReference, setTransactionReference] = useState("");
  
  const { currenciesPayload,getTransactionSuccess, getTransactionLoading} = useSelector(({ reducer }:any) => {
    return {
      getTransactionSuccess: reducer?.getTransactionSuccess,
      getTransactionLoading: reducer?.getTransactionLoading,
      getTransactionError: reducer?.getTransactionError,
      currenciesPayload: reducer?.currenciesPayload

    };
  });
 
  const handleSearch = _debounce((event:React.ChangeEvent<HTMLInputElement>) => {
    setTransactionReference(event.target.value)
  }, 1000);

  const view = (val:{transactionReference: string, clientReference: string, channel: string, amount: number, country: string, currency: string, createdAt: string, transactionStatus:string, settlementStatus:string}) => {
    const details = [
      {title: "Transaction Reference", value: val.transactionReference}, 
      {title: "Client Reference", value: val.clientReference}, 
      {title: "Transaction Status", value: val.transactionStatus},
      {title: "Settlement Status", value: val.settlementStatus},
      {title: "Amount", value: `${val.amount} (${val.currency})`},
      {title: "Channel", value: val.channel},
      {title: "Currency", value: val.currency},
      {title: "Country", value: val.country},
      {title: "Transaction Date", value: DateFormat(val.createdAt)},
       ]
    setViewDetail(details);
    setSelectedRow(true)
  }

  useEffect(()=> {
    dispatch(getCurrencies());
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
     dispatch(getTransactions(currencySearch?.value,transactionstatus?.value, settlementStatus?.value, page?.pageNumber, 10, transactionReference))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currencySearch, transactionstatus, settlementStatus, transactionReference])

  return (
    <div className="transactions-container">
      <div className="table-items">
            <div className="search-container">
              <input type="text" placeholder="Search by Reference" className="form-control" onChange={handleSearch} />
              <i className="ri-search-line"></i>
            </div> 
      <div className="dropdown-items">
          <div className="input-content">
              <Select placeholder="Currency" options={[{value: "", label: "Currency"},...(currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))||[])]} value={currencySearch} onChange={(e)=>setCurrencySearch(e)} className="mr-2" />
         </div>
          <div className="input-content">
              <Select placeholder="Transaction status" options={transactionStatuses} value={transactionstatus} onChange={(e:any)=>setTransactionstatus(e)} className="mr-2"/>
         </div>
          <div className="input-content">
              <Select placeholder="Settlement Status" options={settlementStatuses} value={settlementStatus} onChange={(e:any)=>setSettlementStatus(e)} className="mr-2"/>
         </div>
          <div className="input-content">
              <Button color="primary" className="export-btn" onClick={()=>setOpenExport(true)}>Export</Button>
         </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
            <th>Transaction Reference</th>
             <th>Transaction Status</th>
            <th>Transaction Date</th>
            <th>Settlement Status</th>
              <th>Amount</th>
              <th>Channel</th>
              <th>Country</th>
              <th>Currency</th>
              <th></th>
            </tr>
          </thead>
          {getTransactionLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={8} />
            </tbody>
          ) : getTransactionSuccess?.data?.data?.length > 0 ?(
            <tbody>
                  {getTransactionSuccess?.data?.data?.map(val =>(<tr key={val?.id}>
                    <td>{val?.transactionReference}</td>
                     <td>{val?.transactionStatus}</td>
                    <td>{DateFormat(val?.createdAt)}</td> 
                    <td>{val?.settlementStatus}</td> 
                    <td>{val?.amount}({val?.currency})</td>
                    <td>{val?.channel}</td>
                    <td>{val?.country}</td>
                    <td>{val?.currency}</td>
                    <td><div className="options" onMouseLeave={()=>setShow(0)}  onMouseEnter={()=>setShow(val.id)}><MoreIconIcon/>
                      {show === val.id && <div className="more-options">
                         <div className="option" onClick={()=>{setViewWebhook(true); setReference(val?.clientReference)}}>Renotify</div>
                         <div className="option" onClick={()=>view(val)}>View</div>
                      </div>}
                    </div></td>
                  </tr>))}
            </tbody>
          ):<tbody>
            <tr><td colSpan={9}><EmptyState /></td></tr>
          </tbody>}
        </table>
        </div>
        <hr className="separate-line" />
      <div>
          <div>
            {getTransactionSuccess && <TablePagination
              totalCount={getTransactionSuccess?.data?.totalItems}
              currentPage={getTransactionSuccess?.data?.pageNumber}
              pageSize={getTransactionSuccess?.data?.pageSize}
              onPageChange={page => setPage(prev => ({ ...prev, pageNumber: page }))}
              totalPages={getTransactionSuccess?.data?.totalPages}
            />}
          </div>
      </div>
      <ViewModal
        title="Transaction Details"
        data={viewDetail}
        toggle={() => setSelectedRow(!selectedRow)}
        open={!!selectedRow}
      />
      <ExportModal show={openExport} toggleModal={()=>setOpenExport(!openExport)}/>
      <WebhookNotification reference={reference} show={viewWebhook} toggleModal={()=>setViewWebhook(!viewWebhook)}/>
      
    </div>
  );
};

export default Transactions;
