import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import URLS from "../../helpers/urls";
import { ArrowDownIcon } from "../icons";
import "./customTab.scss";

type Props = {
  children?:any, 
  data?: any, 
  defaultActiveTab?:any, 
  onTabItemClick?:any, 
  useContent?:boolean,
}
const CustomTab: React.FC<Props> = ({
  children,
  data,
  defaultActiveTab,
  onTabItemClick,
  useContent = true,
}) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleLinkClick = (event: any, item:any) => {
    onTabItemClick && onTabItemClick(item);
    setActiveTab(item.id);
      setOpenDropDown(!openDropDown)
  };

  const subNavigation = (e, link: string) => {
    e.preventDefault();
    history.push(link);
    return;
  }
  useEffect(()=>{
    switch(history.location.pathname) {
      case "/app/collect/overview":
       setActiveTab(1);
       break;
      case "/app/collect/transactions":
        setActiveTab(3);
        break;
      case "/app/collect/settlements/overview":
        setActiveTab(4);
        break;
      case "/app/collect/settlements/sub-account":
        setActiveTab(4);
        break;
      case "/app/collect/settlements/profile":
        setActiveTab(4);
        break;
      case "/app/collect/charge-back":
        setActiveTab(5);
        break;
      case "/app/collect/setting":
        setActiveTab(6);
        break;
      case "/app/collect/linkings":
        setActiveTab(7);
        break;
      default:
        setActiveTab(1)
    }
  }, [history.location.pathname])

  return (
    <div className="custom-nav-container">
      <Nav>
        {data?.map((item: any) => (
          <NavItem key={item.id}>
            {item?.routeLink ? (
              <Link
                className={`nav-link sp-navlink ${
                  item.id === activeTab ? "active" : ""
                }`}
                to={item.routeLink===URLS.Settlements?"#":item.routeLink}
                onClick={(e) => handleLinkClick(e, item)}
              >
                {item?.title} {item?.subMenu?.length && <span className="arrow-down"><ArrowDownIcon/> </span>}
                {item?.subMenu?.length && activeTab===4 && openDropDown && <div className="tab-sub-menu">
                  {item?.subMenu.map(val=>(<div key={val.value} className={`${val.link === history.location.pathname?"tab-item-active":""} tab-item`} onClick={(e)=>subNavigation(e, val.link)}>{val.value}</div>))}
                </div>}
              </Link>
            ) : (
              <NavLink
                className={`sp-navlink ${item.disabled ? "disabled" : ""}`}
                active={item.id === activeTab}
                disabled={item.disabled}
                onClick={(e) => !item.disabled && handleLinkClick(e, item)}
              >
                <>{item?.title}</>
              </NavLink>
            )}
          </NavItem>
        ))}
      </Nav>

      {useContent && (
        <TabContent activeTab={activeTab}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              {data?.map((item:any) => (
                <TabPane key={item.id} tabId={item.id}>
                  <Row>
                    <Col sm="12">{item?.content}</Col>
                  </Row>
                </TabPane>
              ))}
            </>
          )}
        </TabContent>
      )}
    </div>
  );
};

export default CustomTab;
