import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CanvasJSReact from '../../../assets/chart/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const BarChart:React.FC<{data: any}> = ({data}) => {
    const [list, setList] = useState<any>([]);

    useEffect(()=> {
        let items: any = []
        for(let dt in data) {
            items.push({label: dt, y: Number(data[dt]) })
        }
        setList([...items])
    }, [data]);

    const options = {
        title: {
            text: ""
        },
        data: [
        {
            // Change type to "doughnut", "line", "splineArea", etc.
            type: "column",
            dataPoints: list
        }
        ]
    }

    return (
    <div>
        <CanvasJSChart options = {options}
        />
    </div>
    );
}

export default BarChart