import { combineReducers } from "redux";
import Reducer from "./reducer";
import notificationReducer from "./notification";

const reducers = combineReducers({
  reducer: Reducer,
  notification: notificationReducer
})
export default reducers;

export type State = ReturnType<typeof reducers>