export const URLS = {
    HOME: "/",
    COLLECTION: "/app/collect/collection",
    Overview: "/app/collect/overview",
    ChargeBack: "/app/collect/charge-back",
    Transactions: "/app/collect/transactions",
    Setting: "/app/collect/setting",
    Settlements: "/app/collect/settlements",
    SettlementOverview: "/app/collect/settlements/overview",
    SettlementsSubAccount: "/app/collect/settlements/sub-account",
    SettlementsProfile: "/app/collect/settlements/profile",
    Linking: "/app/collect/linkings",
    PaymentLinking: "/app/collect/payment-link",
}

export default URLS;
