import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import Table from "vgg-ui-react/dist/components/Core/Table";
import { Button } from "reactstrap";
import LinkModal from "./LinkModal/LinkModal";
// import { getCollectPaymentLink } from "redux/actions/smartCheckInstance";
import _ from "lodash";
import { format } from "date-fns";

import "./links.scss";
import FilterDropdown from "../../shared/filterDropdown/FilterDropdown";
import EmptyState from "../../shared/empty/empty";
import { LoadingTableContent } from "../../components/loader/Loader";
import TablePagination from "../../shared/tablePagination/tablePagination";
import { getCurrencies, getPaymentLink, togglePaymentLink } from "../../redux/actions/action";
import ToggleModal from "../../components/toggleModal/toggleModal";

const Links = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [payload, setPayload] = useState<any>();
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [data, setData] = useState<any>([]);

 
  const { getCollectPaymentLinkLoading, getCollectPaymentLinkSuccess, toggleCollectPaymentLinkSuccess, toggleCollectPaymentLinkLoading} = useSelector(({ reducer }:any) => {
    return {
      getCollectPaymentLinkSuccess: reducer?.getCollectPaymentLinkSuccess,
      getCollectPaymentLinkLoading: reducer?.getCollectPaymentLinkLoading,
      getCollectPaymentLinkError: reducer?.getCollectPaymentLinkError,
      toggleCollectPaymentLinkSuccess: reducer?.toggleCollectPaymentLinkSuccess,
      toggleCollectPaymentLinkLoading: reducer?.toggleCollectPaymentLinkLoading,
      toggleCollectPaymentLinkError: reducer?.toggleCollectPaymentLinkError,
     };
  });

  const onSearch = _.debounce((e) => {
    setSearchField(e.target.value);
    dispatch(getPaymentLink(currentPage, e.target.value));
  }, 1000);

  const handleChange = (id, status) => {
    setDataId(id);
    setShowStatus(status);
    setShowToggleModal(true)
  }

  const onEdit = (val) =>{
    const {id,
      linkName,
      amount,
      currency,
      description,
      isMultipleUse} = val
     setPayload({
      id,
      linkName,
      amount,
      currency,
      description,
      isMultipleUse
     });
     setShowLinkModal(true)
  }

  useEffect(() => {
    dispatch(getPaymentLink(currentPage, searchField));
    dispatch(getCurrencies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    if(!toggleCollectPaymentLinkLoading && toggleCollectPaymentLinkSuccess){
      setShowToggleModal(false);
      let i = 0;
      for(let dt of data?.data?.responseData?.items){
          if(dt?.id === dataId){
            data["data"]["responseData"]["items"][i]["isEnabled"] = !data["data"]["responseData"]["items"][i]["isEnabled"]
             setData(data)
             return
          }
          i++
      }
    }
  }, [toggleCollectPaymentLinkLoading])
  
  useEffect(()=> {
    if(!getCollectPaymentLinkLoading && getCollectPaymentLinkSuccess){
      setData(getCollectPaymentLinkSuccess)
    }
  }, [getCollectPaymentLinkLoading])

  return (
    <div className="links-container">
       <div className="sc-links table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Links</h4>
      </div>

      <div className=" px-3 pt-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="search-container">
              <input type="text" placeholder="Search" className="form-control" onChange={onSearch} />
              <i className="ri-search-line"></i>
            </div> 
          <div className="d-flex align-items-center">
            {/* <FilterDropdown
              dropdownOpen={showFilterDropdown}
              onSubmit={applyFilter}
              disabled={true}
              toggleDropdown={() => setShowFilterDropdown(!showFilterDropdown)}
            /> */}
            <Button
              color="primary"
              className=""
              onClick={() => setShowLinkModal(true)}
            >
              New Link
            </Button>
            <LinkModal
              show={showLinkModal}
              payload={payload}
              toggleModal={() => setShowLinkModal(!showLinkModal)}
            />
          </div>
        </div>
        <Table cellVariants="default" className="overflow-auto table-striped">
          <thead>
            <tr>
              <th>Reference</th>
              <th>Link Name</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Merchant Name</th>
              <th>Status</th>
              <th>Creation Date</th>
              <th></th>
            </tr>
          </thead>
          {getCollectPaymentLinkLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={7} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {data?.data?.responseData?.items &&
                getCollectPaymentLinkSuccess?.data?.responseData?.items?.map((val) => (
                  <tr key={val?.id}>
                    <td>{val?.linkReference || "--"}</td>
                    <td>{val?.linkName || "--"}</td>
                    <td>{val?.currency || "--"}</td>
                    <td>{val?.amount || "--"}</td>
                    <td>{val?.merchantName || "--"}</td>
                    <td>{val?.status || "--"}</td>
                    <td>
                      {val?.createdAt.split("T")[0] || "--"}
                    </td>
                    <td>
                      <div className="switch">
                      <Switch
                          onChange={() => handleChange(val?.id, val?.isEnabled)}
                          onColor="#175FFF"
                          checked={!!val?.isEnabled}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                        <span className="edit" onClick={()=>onEdit(val)}>Edit</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>

      {(!getCollectPaymentLinkLoading &&
        getCollectPaymentLinkSuccess?.data?.responseData?.items?.length < 1) ||
      !getCollectPaymentLinkSuccess?.data?.responseData?.items ? (
        <EmptyState />
      ) : null}
      {getCollectPaymentLinkSuccess?.data?.responseData?.items &&
        getCollectPaymentLinkSuccess?.data?.responseData?.items?.length > 0 && (
          <div className="px-3">
            <TablePagination
              totalCount={getCollectPaymentLinkSuccess?.data?.responseData?.count}
              currentPage={getCollectPaymentLinkSuccess?.data?.responseData?.pageNumber}
              pageSize={getCollectPaymentLinkSuccess?.data?.responseData?.pageSize}
              onPageChange={(page) => setCurrentPage(page)}
              totalPages={getCollectPaymentLinkSuccess?.data?.responseData?.pages}
            />
          </div>
        )}
    </div>
          <ToggleModal
              title={
                  showStatus ? "Disable item" : "Enable Item"
              }
              showToggleModal={showToggleModal}
              toggleForm={()=>setShowToggleModal(!showToggleModal)}
              onToggle={() => dispatch(togglePaymentLink(dataId, showStatus))}
              toggling={toggleCollectPaymentLinkLoading}
              toggleBtn={showStatus ? "Disable" : "Enable"}
              textContent={
                  showStatus
                      ? "Are you sure you want to disable this item?"
                      : "Are you sure you want to enable this item?"
              }
          />
    </div>
  );
};

export default Links;
