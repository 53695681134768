import React, { useState, useEffect, useCallback } from "react";
import Select from 'react-select';
import {debounce} from 'lodash';

import {useDispatch, useSelector} from "react-redux"
import Button from "../../../../components/button/Button";
import "../../settlements.scss";
import CustomModal from "../../../../components/modal/Modal";
import { CancelIcon } from "../../../../components/icons";
import { getBanks, postSettlementSubAccount, getSettlementSubAccount, getCurrencies, getSupportedCountry, getNameEnquiry } from "../../../../redux/actions/action";
import { errorMessage, successMessage } from "../../../../redux/actions/notification";
import { Spinner } from "reactstrap";


type PayloadType = {
    country?: any,
    bank?: any,
    currency?: any,
    countryCode?: string,
    accountNumber?: string,
    bankCode?: string,
    accountName?: string,
    currencyCode?: string,
    
}

type PropsType = {
  openSubAccountModal: boolean, 
  setOpenSubAccountModal: any,
  id?: any,
  setEdit?: any,
    edit?: boolean,
    data?: any,
}
const Form: React.FC<PropsType> = ({openSubAccountModal, setOpenSubAccountModal, edit, data, id}) => {
  const [payload, setPayload] = useState<PayloadType>();
  const [submitStatus, setSubmitStatus] = useState(false);
  const dispatch = useDispatch();
  
  const { supportedCountryPayload,currenciesError, currenciesLoading,currenciesPayload, getBanksLoading, getBanksSuccess, postSettlementSubAccountLoading, postSettlementSubAccountError, postSettlementSubAccountSuccess, bankNameEnquiryLoading, bankNameEnquiryError, bankNameEnquirySuccess} = useSelector(({ reducer }:any) => {
    return {
      currenciesPayload: reducer?.currenciesPayload,
      currenciesLoading: reducer?.currenciesLoading,
      currenciesError: reducer?.currenciesError,
      getBanksSuccess: reducer?.getBanksSuccess,
      getBanksLoading: reducer?.getBanksLoading,
      bankNameEnquirySuccess: reducer?.bankNameEnquirySuccess,
      bankNameEnquiryLoading: reducer?.bankNameEnquiryLoading,
      bankNameEnquiryError: reducer?.bankNameEnquiryError,
      postSettlementSubAccountSuccess: reducer?.postSettlementSubAccountSuccess,
      postSettlementSubAccountLoading: reducer?.postSettlementSubAccountLoading,
      postSettlementSubAccountError: reducer?.postSettlementSubAccountError,
      supportedCountryPayload: reducer?.supportedCountryPayload,
      supportedCountryLoading: reducer?.supportedCountryLoading,
      supportedCountryError: reducer?.supportedCountryError,
    };
  });
  
  
  const onSelectCountry = (e) => {
    dispatch(getBanks(e.value));
    setPayload({...payload, countryCode: e?.value, country: e, currency: null, bank: null, accountName: "", accountNumber: ""});
    dispatch(getCurrencies(e?.value))
  }

  const submit = () => {
    setSubmitStatus(true);
    if(payload?.accountName && payload.countryCode && payload.bankCode && payload.currencyCode) {
      dispatch(postSettlementSubAccount(payload, id))
    }
  }

  const debounceText = useCallback(debounce(async(srhTxt) => {
    if(payload?.bankCode && payload?.countryCode) {
      dispatch(getNameEnquiry(srhTxt, payload.bankCode, payload.countryCode ));
    }
  }, 500), [payload?.bankCode, payload?.countryCode])
  
  
  const handleSearchChange = (e: any) => {
    debounceText(e.target.value)
    setPayload({...payload, accountNumber: e.target.value})
  };
  

  useEffect(()=>{
    if(edit) {
      dispatch(getBanks(data.countryCode));
      let country: any=[];
      let currency:any=[];
      supportedCountryPayload.data?.responseData?.forEach(val => {
        if(val.countryCode === data.countryCode) {
             country.push({value: val?.countryCode, label: val?.countryName})
        }
      })
      currenciesPayload.data?.forEach(val => {
        if(val.currencyCode === data.currencyCode) {
            currency.push({value: val?.currencyCode, label: val?.currencyName})
        }
      })
      
      setPayload({
        country: country?.[0],
        currency: currency?.[0],
        countryCode: country?.[0]?.value,
        accountNumber: data?.accountNumber,
        bankCode: data.bankCode,
        accountName: data?.accountName,
        currencyCode: currency?.[0]?.value,       
    })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])

  useEffect(()=>{
    dispatch(getSupportedCountry("setupAccount")); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(payload?.bankCode && payload?.countryCode && payload?.accountNumber) {
      dispatch(getNameEnquiry(payload.accountNumber, payload.bankCode, payload.countryCode ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.bankCode, payload?.countryCode])

  
  useEffect(()=> {
    if(getBanksSuccess?.data?.responseData && !getBanksLoading) {
     let banks: any = []
     getBanksSuccess?.data?.responseData?.forEach(val => {
      if(val.bankCode === data.bankCode) {
          banks.push({value: val?.bankCode, label: val?.bankName})
      }
    })
    setPayload({
      ...payload,
      bank: banks?.[0],
    })
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBanksLoading])

  useEffect(()=> {
   if(postSettlementSubAccountSuccess?.data?.requestSuccessful && !postSettlementSubAccountLoading && submitStatus) {
     setOpenSubAccountModal();
     dispatch(successMessage(`Sub-Account ${id?"edition":"creation"} was successful`))
     dispatch(getSettlementSubAccount());
   }else if(postSettlementSubAccountError && !postSettlementSubAccountLoading && submitStatus) {
    dispatch(errorMessage(postSettlementSubAccountError?.data?.message|| `Unable to ${id?"edit":"create"} Sub-Account, please try again`))
   }else if(postSettlementSubAccountSuccess?.data?.requestSuccessful===false && !postSettlementSubAccountLoading && submitStatus) {
    dispatch(errorMessage(postSettlementSubAccountSuccess?.data?.message|| `Unable to ${id?"edit":"create"} Sub-Account, please try again`))
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSettlementSubAccountLoading])

  useEffect(()=> {
    if(bankNameEnquirySuccess?.data && !bankNameEnquiryLoading) {
      setPayload({...payload, accountName: bankNameEnquirySuccess?.data?.responseData})
    }else if(bankNameEnquiryError && !bankNameEnquiryLoading) {
    dispatch(errorMessage(bankNameEnquiryError?.data?.message|| "Unable to retrieve your account name, please try again"))
   }else if(bankNameEnquirySuccess?.data?.requestSuccessful ===false && !bankNameEnquiryLoading) {
    dispatch(errorMessage(bankNameEnquirySuccess?.data?.message|| "Unable to retrieve your account name, please try again"))
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankNameEnquiryLoading]);

  
  return (
          <CustomModal
            openModal={openSubAccountModal}
            setOpenModal={() => setOpenSubAccountModal}
          >
            <div className="add-modal-container"> 
              <div className="modal-title">Add Sub Account <span className="cursor" onClick={()=>setOpenSubAccountModal(false)}><CancelIcon/></span></div>
              <div className="form-group">
                <label htmlFor="sub-account">Country</label>
                <Select
                 options={supportedCountryPayload?.data?.responseData?.map(val =>({value: val?.countryCode, label: val?.countryName}))}
                  value={payload?.country} onChange={onSelectCountry}/>
              </div>
              {submitStatus && !payload?.countryCode && <div className="error-message">Country is required</div>}
              {currenciesPayload?.data?.length === 0 && <div className="error-message">No currency attached to this country</div>}
              
              {currenciesLoading?<Spinner color="primary"/>: <>{currenciesPayload && <div className="form-group">
                    <label className="">Currency</label>
                        <div className="select-country">
                        <Select
                            options={currenciesPayload?.data?.map(val =>({value: val?.currencyCode, label: val?.currencyName}))}
                            value={payload?.currency} onChange={(e)=>setPayload({...payload, currencyCode: e.value, currency: e})}/>
                        </div>
                </div>}</>}
              <div>
              {submitStatus && !payload?.currencyCode && <div className="error-message">Currency is required</div>}

              <div className="form-group">
                <label htmlFor="sub-account">Bank Name</label>
                <Select options={getBanksSuccess?.data?.responseData?.map(val =>({value: val?.bankCode, label: val?.bankName}))} value={payload?.bank} onChange={(e) =>setPayload({...payload, bankCode: e?.value, bank: e})}/>
              </div>
              {!getBanksLoading && getBanksSuccess?.data?.requestSuccessful === false && <div className="error-message">{getBanksSuccess?.data?.message||"Unable to retrieve banks, please try again"}</div>}
              {getBanksLoading && <div className="error-message">Loading Banks</div>}
              {submitStatus && !payload?.bankCode && <div className="error-message">Bank Name is required</div>}

              <div className="form-group">
                <label htmlFor="sub-account">Account Number</label>
                <input className="form-control" value={payload?.accountNumber} type="number" onChange={handleSearchChange}/>
              </div>
              {submitStatus && !payload?.accountNumber && <div className="error-message">Account Number is required</div>}

              <div className="form-group">
                <label htmlFor="sub-account">Account Name</label>
                <input className="form-control" disabled={true} value={payload?.accountName} type="text"/>
              </div>
              {bankNameEnquiryLoading && <div className="error-message">Fetching account name</div>}
              {submitStatus && !payload?.accountName && <div className="error-message">Account Name is required</div>}

              
                  <Button
                label={edit?"Update": "Save"}
                className="open-btn"
                background="primary"
                size="md"
                width="100%"
                onClick={submit}
                loading={postSettlementSubAccountLoading}
              />
              </div>
            </div>
          </CustomModal>
    );
};

export default Form;
