import React, { useState } from "react";
import Select from 'react-select';
import {useSelector, useDispatch} from "react-redux";
import { LoadingTableContent } from "../../components/loader/Loader";
import Table from "vgg-ui-react/dist/components/Core/Table";

import "./chargeBack.scss";
import TablePagination from "../../shared/tablePagination/tablePagination";
import ViewModal from "../../components/viewModal/ViewModal";
import { useEffect } from "react";
import { approveChargeBack, getChargeBack } from "../../redux/actions/action";
import EmptyState from "../../shared/empty/empty";
import DeleteModal from "../../components/modal/DeleteModal";
import { errorMessage, successMessage } from "../../redux/actions/notification";
import { DateFormat } from "../../shared/constants/constant";

type viewPayload = {
  title: string,
  value: string
}
const Chargeback = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [id, setId] = useState(0);
  const [approve, setApprove] = useState(false);
  const [currency, setCurrency] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);
  const [viewDetail, setViewDetail] = useState<Array<viewPayload>>();
  const [selectedCurrency, setSelectedCurrency] = useState<any>("");
  
  const { approveChargeBackError, approveChargeBackSuccess, approveChargeBackLoading, chargeBackSuccess, chargeBackLoading, getOnboardingStatusSuccess} = useSelector(({ reducer }:any) => {
    return {
      getOnboardingStatusSuccess: reducer?.getOnboardingStatusSuccess,
      chargeBackSuccess: reducer?.chargeBackSuccess,
      chargeBackLoading: reducer?.chargeBackLoading,
      chargeBackError: reducer?.chargeBackError,
      approveChargeBackSuccess: reducer?.approveChargeBackSuccess,
      approveChargeBackLoading: reducer?.approveChargeBackLoading,
      approveChargeBackError: reducer?.approveChargeBackError,
    };
  });

  const onselectCurrency = (e: any) => {
    setSelectedCurrency(e);
    dispatch(getChargeBack(1, 10, e.value));
  }

  const view = (val: any) => {
    const details = [
      {title: "Transaction Reference", value: val?.transactionReference}, 
      {title: "Curreny", value: val?.currencyCode},
      {title: "Amount", value: val?.amount},
      {title: "Chargeback Type", value: val?.chargeType},
      {title: "Merchant Name", value: val?.merchant?.merchantName},
      {title: "Status", value: val?.status},
      {title: "Reason", value: val?.reason},
      {title: "Date", value: DateFormat(val?.createdAt)},
    ]
    setViewDetail(details);
    setSelectedRow(true)
  }

  const onApproveChargeBack = (val: any) => {
    setId(val?.id)
    setApprove(true)
  }

  const onConfirmApprove = () => {
    setApproveStatus(true)
    dispatch(approveChargeBack(id))
  }
  

  useEffect(() => {
    dispatch(getChargeBack(page.pageNumber, 10, selectedCurrency?.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageNumber])

  useEffect(()=>{
    if(getOnboardingStatusSuccess?.data) {
       let list: any = [];
     getOnboardingStatusSuccess?.data?.responseData?.merchantConfigs?.forEach(val => {
        if(val?.currency) {
           list.push({label: val?.currency, value: val?.currency})
        }
     })
     setCurrency([{label: "", value: ""}, ...list])
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [getOnboardingStatusSuccess])


useEffect(()=> {
  if(approveChargeBackSuccess?.data?.requestSuccessful && !approveChargeBackLoading && approveStatus) {
    setApprove(false);
    setApproveStatus(false);
    dispatch(successMessage("Chargeback approval was successful"))
    dispatch(getChargeBack(page.pageNumber, 10, selectedCurrency?.value));
  }else if(approveChargeBackError && !approveChargeBackLoading && approveStatus) {
    setApproveStatus(false);
   dispatch(errorMessage(approveChargeBackError?.data?.message|| "Unable to approve Profile, please try again"))
  }else if(approveChargeBackSuccess?.data?.requestSuccessful===false && !approveChargeBackLoading && approveStatus) {
    setApproveStatus(false);
   dispatch(errorMessage(approveChargeBackSuccess?.data?.message|| "Unable to approve Profile, please try again"))
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [approveChargeBackLoading])
 
  return (
    <div className="chargeback-container">
      <div className="table-items">
          <div className="search-container">
            {/* <input type="text" placeholder="Search" className="form-control" onChange={handleSearch} />
            <i className="ri-search-line"></i> */}
          </div>
          <>
          {/* <FilterDropdown
          buttonContent={selectedFilter !== "All" && selectedFilter}
          items={filterOptions}
          onItemClick={item => {
            setSelectedFilter(item);
            dispatch(fetchChargebackData({ ...page, status: item === "All" ? "" : item.toUpperCase() }));
          }}
        /> */}
          <Select options={currency} value={selectedCurrency} onChange={onselectCurrency}  />
          </>
        
      </div>
      <div className="table-content">
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th>Transaction Reference</th>
              <th>Amount</th>
              <th>Chargeback Type</th>
              <th>Status</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          {chargeBackLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <tbody>
                  {chargeBackSuccess?.data?.data?.map(val =>(<tr key={val.id}>
                    <td>{val?.transactionReference}</td>
                    <td>{val?.amount}</td>
                    <td>{val?.chargeType}</td>
                    <td>{val?.status}</td>
                    <td>{DateFormat(val?.createdAt)}</td>
                    <td>
                      <div className="d-flex">
                      {val?.status === "Pending" && <div className="action" onClick={()=>onApproveChargeBack(val)}>
                        Approve
                      </div>}
                      <div className="action" onClick={()=>view(val)}>
                        View
                      </div>
                      </div>
                    </td>
                  </tr>))}
            </tbody>
          )}
        </Table>
        {!chargeBackLoading && chargeBackSuccess?.data?.data?.length === 0 && <EmptyState />}
        </div>
        <hr className="separate-line" />
      <div>
            {chargeBackSuccess?.data && <TablePagination
              totalCount={chargeBackSuccess?.data?.totalItems}
              currentPage={chargeBackSuccess?.data?.pageNumber}
              pageSize={chargeBackSuccess?.data?.pageSize}
              onPageChange={page => setPage(prev => ({ ...prev, pageNumber: page }))}
              totalPages={chargeBackSuccess?.data?.totalPages}
            />}
      </div>
      <ViewModal
        title="Chargeback Details"
        data={viewDetail}
        toggle={() => setSelectedRow(!selectedRow)}
        open={!!selectedRow}
      />
    <DeleteModal loading={approveChargeBackLoading} btnBackground="primary" label="Approve" openModal={approve} setOpenModal={setApprove} title="Approve Chargeback" description="Are you sure about approving this chargeback" onDelete={onConfirmApprove}/>

    </div>

  );
};

export default Chargeback;
