import React from "react";
import { Button } from "vgg-ui-react/dist/components/Core";
import BackButton from "../../../components/backButton/BackButton";
import {ReactComponent as InfoIcon} from "../../../assets/images/infolarge.svg";
import "./awaitingApprovalPage.scss";


const AwaitingApprovalPage = ({ description, setOpenSuccess, setOpen, notApprovedCountry }: { description?: string, notApprovedCountry:any, setOpenSuccess:any, setOpen:any }) => { 
  return (
    <>
    <div className="awaiting-onboard-wrapper">
    <span onClick={()=>setOpenSuccess(false)}><BackButton label="Collect" /></span>
    <div className="d-flex flex-column align-items-center justify-content-center content-wrapper">
      <div className="mb-3">
        <InfoIcon />
      </div>
      <div className="mb-3">
        <h3 className="success-title">Onboard Pending!</h3>
      </div>
      <p className="mb-3 success-content">{description}</p>
      {
        <p className="mb-3 success-content">
          {   <strong className="mr-1">
                {notApprovedCountry?.countryCode}
              </strong>}
          : Awaiting Approval
        </p>
      }

      <Button
        label="Onboard New Country"
        background="blue"
        onClick={() => setOpen(true)}
      />
    </div>
  </div>
    </>
  );
}

export default AwaitingApprovalPage;
